import FooterLink from "additionalComponents/FooterLink";

const Footer = () => {
  return (
    <footer className="mt-auto">
      <div className="footer">
        <div className="basis-1/3">
          <span className="uppercase">&copy; 2024 </span>
        </div>
        <FooterLink />
      </div>
    </footer>
  );
};

export default Footer;
