import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import Button from "components/Button";

import helper from "helper/helper";
import server from "helper/server";

const CustomShow = (props) => {
  const navigate = useNavigate();

  const { id } = props;

  const [data, setData] = useState(null);

  useEffect(() => {
    id && getDetail();
  }, [id]);

  const getDetail = async () => {
    if (id) {
      const response = await server.call({
        url: "/news/detail/" + id,
        method: "GET",
      });
      if (response.data.success && response.data.data) {
        setData(response.data.data);
      }
    }
  };

  return (
    <>
      {data && (
        <div className="container mt-20 pb-12 bg-white">
          <div className="max-w-[767px] mx-auto">
            <div className="flex flex-row justify-center p-12">
              <h3 className="text-gray-700 font-light text-3xl">What`s New</h3>
            </div>
            <div className="px-6 py-2 border-l-4 border-l-orange-500">
              <div className="flex flex-row gap-8">
                <span>{helper.setJapanDate(data.created_at)}</span>
                <span
                  className="text-white text-xs px-6 py-0.5"
                  style={{
                    backgroundColor: helper.typeColor(data.type),
                  }}
                >
                  {data.type}
                </span>
              </div>
              <div className="mt-4">
                <span className="text-xl font-medium">{data.title}</span>
              </div>
            </div>
            <div className="border-b border-b-gray-500 mt-4"></div>

            <div className="py-4">
              <p className="font-extralight text-base leading-10">
                {data.content}
              </p>
            </div>

            <div className="">
              <Button
                color="danger"
                className="uppercase px-4 py-0.5 rounded-none"
                onClick={() => navigate("auth/login")}
              >
                <span className="la la-undo text-xl mr-2"></span>
                戻る
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

CustomShow.propTypes = {
  id: PropTypes.number,
};

export default CustomShow;
