// import React, { useEffect, useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";

// redux
// import { setAlert, setConstructionsGrid } from "reducers/actions";

// import { BaseGridLoading } from "baseComponents";

import SubHeaderTitle from "additionalComponents/SubHeaderTitle";
import AddCard from "customComponents/Constructions/CustomThumb/AddCard";
// import Detail from "customComponents/Constructions/CustomThumb/Detail";

// import server from "helper/server";
// import helper from "helper/helper";

import { useAuth } from "context/auth";

const CustomThumb = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const { userData } = useAuth();
  const role = userData ? userData.role : null;

  // const [loading, setLoading] = useState(false);

  // const constructionsGridData = useSelector(
  //   (state) => state.constructions.constructionsGridData
  // );

  // useEffect(() => {
  //   getList();
  // }, []);

  // const getList = async () => {
  //   setLoading(true);

  //   let rootFilter = ["status", "=", "active"];
  //   let skip = 0;
  //   let take = 10;
  //   let sort = [{ selector: "id", desc: true }];

  //   let select = JSON.stringify([
  //     "*",
  //     { CreatedUser: ["id", "firstname"] },
  //     { UpdatedUser: ["id", "firstname"] },
  //   ]);

  //   let filter = [];

  //   if (role === "Customer") {
  //     filter = ["created_by", "=", userData.id];
  //     let params = helper.getRequest(
  //       { skip, take, sort, filter: null },
  //       rootFilter,
  //       null
  //     );
  //     params += `select=${select}&`;
  //     params = params.slice(0, -1);

  //     const response = await server.call({
  //       url: "/constructions/grid-list" + params,
  //       method: "GET",
  //     });

  //     if (response.data && response.data.data) {
  //       if (response.data.success === false) {
  //         dispatch(setAlert({ show: true, success: false, message: "間違い" }));
  //       }
  //       response.data.success &&
  //         dispatch(setConstructionsGrid(response.data.data));
  //     }
  //   }

  //   setLoading(false);
  // };

  return (
    <>
      <SubHeaderTitle>[申し込み]</SubHeaderTitle>
      <div className="grid auto-cols-auto grid-cols-4 lg:grid-cols-3 md:grid-cols-2 max-sm:grid-cols-1 gap-2 min-h-28">
        {/* {loading ? (
          <div className="relative">
            <BaseGridLoading />
          </div>
        ) : (
          <>
            {constructionsGridData.rows.map((e, key) => (
              <Detail
                key={key}
                data={e}
                onClick={() => navigate("/admin/construction/confirm/" + e.id)}
              />
            ))}
          </>
        )} */}
        {role === "Customer" && (
          <AddCard onClick={() => navigate("/admin/construction/create")} />
        )}
      </div>
      <div className="mt-8">
        <span className="text-lg">
          {/* 申し込みをキャンセルする場合は、現場管理者へ直接ご連絡ください。 */}
          完了した申し込みをキャンセルする場合は、現場管理者へ直接ご連絡ください。
        </span>
      </div>
    </>
  );
};

export default CustomThumb;
