// Show alert
export const SHOW_ALERT = "SHOW_ALERT";

// Show users grid loading
export const SHOW_GRID_LOADING = "SHOW_GRID_LOADING";

export const SHOW_CONFIRM_DIALOG = "SHOW_CONFIRM_DIALOG";

export const SHOW_CONFIRM_DATA = "SHOW_CONFIRM_DATA";

// pdf & image
export const SHOW_IMAGE_DIALOG = "SHOW_IMAGE_DIALOG";

export const SHOW_PDF_DIALOG = "SHOW_PDF_DIALOG";

export const IMAGE_SRC = "IMAGE_SRC";

export const PDF_SRC = "PDF_SRC";
