import { combineReducers } from "@reduxjs/toolkit";

import Root from "reducers/RootReducers";
import Custom from "reducers/CustomReducers";

// Settings
import Users from "reducers/crud/UsersReducers";
import Apartments from "reducers/crud/ApartmentsReducers";
import Categories from "reducers/crud/CategoriesReducers";
import Emails from "reducers/crud/EmailsReducers";

import Documents from "reducers/crud/DocumentsReducers";

// new
import Constructions from "reducers/crud/ConstructionsReducers";
import ConstructionItems from "reducers/crud/ConstructionItemsReducers";
import ConstructionDetails from "reducers/crud/ConstructionDetailsReducers";

import Inquiries from "reducers/crud/InquiriesReducers";
import Greetings from "reducers/crud/GreetingsReducers";
import News from "reducers/crud/NewsReducers";

export default combineReducers({
  root: Root,
  custom: Custom,
  documents: Documents,
  users: Users,
  categories: Categories,
  apartments: Apartments,
  emails: Emails,

  // new develope
  constructions: Constructions,
  constructionItems: ConstructionItems,
  constructionDetails: ConstructionDetails,

  inquiries: Inquiries,
  greetings: Greetings,
  news: News,
});
