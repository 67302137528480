import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// redux
import {
  setAlert,
  setShowGridLoading,
  setConstructionsDialog,
  setConstructionsList,
  setConstructionsModifyData,
  setConstructionsEditData,
} from "reducers/actions";

import useConstructionDelete from "reducers/hooks/useConstructionDelete";

// components
import Tooltip from "components/Tooltip";
import Button from "components/Button";

import { BaseDialog } from "baseComponents";

import Grid from "./Grid";
import Form from "./Form";

import server from "helper/server";
import helper from "helper/helper";

const Constructions = () => {
  const dispatch = useDispatch();

  const constructionsDialog = useSelector(
    (state) => state.constructions.constructionsDialog
  );
  const constructionsModifyData = useSelector(
    (state) => state.constructions.constructionsModifyData
  );
  const constructionsEditData = useSelector(
    (state) => state.constructions.constructionsEditData
  );

  const [deleteConstruction] = useConstructionDelete();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  useEffect(() => {
    getList();
  }, [page, size]);

  const getList = async () => {
    dispatch(setShowGridLoading(true));

    let skip = (page - 1) * size;
    let take = size;
    let sort = [{ selector: "id", desc: true }];
    let rootFilter = ["status", "=", "active"];

    let select = JSON.stringify([
      "*",
      { Apartment: ["id", "apartment_name"] },
      { CreatedUser: ["id", "firstname"] },
      { UpdatedUser: ["id", "firstname"] },
    ]);
    let params = helper.getRequest(
      { skip, take, sort, filter: null },
      rootFilter,
      null
    );
    params += `select=${select}&`;
    params = params.slice(0, -1);

    const response = await server.baseList({
      objectName: "constructions",
      params,
    });

    if (response.data && response.data.data) {
      if (response.data.success === false) {
        dispatch(setAlert({ show: true, success: false, message: "間違い" }));
      }
      response.data.success &&
        dispatch(setConstructionsList(response.data.data));
    }

    dispatch(setShowGridLoading(false));
  };

  return (
    <>
      <BaseDialog
        title={"オプション工事"}
        isActive={constructionsDialog}
        hide={() => {
          dispatch(setAlert({ show: false }));
          dispatch(setConstructionsModifyData({}));
          dispatch(setConstructionsEditData({}));
          dispatch(setConstructionsDialog(false));
        }}
        save={() => {}}
        width={"min-lg:w-[420px]"}
      >
        <Form
          changeModifyObject={(data) =>
            dispatch(
              setConstructionsModifyData({
                ...constructionsModifyData,
                ...data,
              })
            )
          }
        />
      </BaseDialog>
      <div className="w-full">
        {/* custom actions */}
        <section className="flex items-start w-full gap-2 mb-3">
          {/* Refresh */}
          <Tooltip content={"リフレッシュ"} placement="bottom">
            <Button icon color="info" onClick={() => getList()}>
              <span className="la la-sync" />
            </Button>
          </Tooltip>
          {/* Add New */}
          {/* <Tooltip content={"新しい"} placement="bottom">
            <Button
              icon
              color="success"
              onClick={() => dispatch(setConstructionsDialog(true))}
            >
              <span className="la la-plus"/>
            </Button>
          </Tooltip> */}
        </section>
        <Grid
          edit={(data) => {
            dispatch(setConstructionsDialog(true));
            dispatch(
              setConstructionsEditData(
                Object.assign(constructionsEditData, data)
              )
            );
          }}
          destroy={(id) => deleteConstruction(id, () => getList())}
          onPageChange={(page) => setPage(page)}
          onPageSizeChange={(size) => setSize(size)}
        />
      </div>
    </>
  );
};

export default Constructions;
