import { useEffect, useState } from "react";

import CustomLoading from "customComponents/Weather/CustomLoading";
import Thumb from "customComponents/Weather/Thumb";

import server from "helper/server";
import { BaseCard } from "baseComponents";

const CardWeekDays = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getWeatherData();
  }, []);

  const getWeatherData = async () => {
    setLoading(true);
    // get res data
    const response = await server.call({ url: "/weather/forecast-service" });

    if (response.data.success && response.data.data) {
      setData(response.data.data);
    }

    setLoading(false);
  };

  return (
    <BaseCard className="mt-8 inline-block overflow-visible">
      {loading ? (
        <CustomLoading />
      ) : (
        <div className="grid grid-flow-col min-md:auto-cols-max max-md:grid-flow-row overflow-visible">
          {data.map((item, key) => (
            <Thumb key={key} item={item} />
          ))}
        </div>
      )}
    </BaseCard>
  );
};

export default CardWeekDays;
