import classNames from "classnames";

import Tooltip from "components/Tooltip";

const FileItem = (props) => {
  const { index, readOnly, fileInfo, show, remove } = props;

  return (
    <>
      {fileInfo ? (
        <div className="w-full flex flex-row items-center justify-start">
          <span
            className={classNames(
              "la",
              {
                "la-file-pdf":
                  fileInfo.extension.replace("application/", "") === "pdf",
              },
              {
                "la-file-image": ["jpg", "jpeg", "gif", "png"].includes(
                  fileInfo.extension.replace("image/", "")
                ),
              },
              "mr-1 text-xl"
            )}
          />
          <Tooltip content={fileInfo.original_name} placement="bottom">
            <span
              className="cursor-pointer text-xl underline decoration-1 underline-offset-2 text-[#333] hover:text-[#80abd1] text-nowrap truncate overflow-hidden"
              onClick={() => show && show(index)}
            >
              {fileInfo.original_name}
            </span>
          </Tooltip>

          {!readOnly && (
            <span
              id={"remove" + index}
              className="la la-times-circle text-xl ml-4 cursor-pointer text-red-600 hover:text-red-900"
              onClick={() => remove && remove(index)}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default FileItem;
