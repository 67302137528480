import {
  SHOW_APARTMENTS_DIALOG,
  APARTMENTS_MODIFY_DATA,
  APARTMENTS_EDIT_DATA,
  APARTMENTS_LIST_DATA,
} from "reducers/actions/apartmentsTypes";

const initialState = {
  apartmentsDialog: false,
  apartmentsModifyData: {},
  apartmentsEditData: {},
  apartmentsListData: { rows: [], count: 0 },
};

const Apartments = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_APARTMENTS_DIALOG:
      return { ...state, apartmentsDialog: action.payload };

    case APARTMENTS_MODIFY_DATA:
      return { ...state, apartmentsModifyData: action.payload };

    case APARTMENTS_EDIT_DATA:
      return { ...state, apartmentsEditData: action.payload };

    case APARTMENTS_LIST_DATA:
      return { ...state, apartmentsListData: action.payload };

    default:
      return state;
  }
};

export default Apartments;
