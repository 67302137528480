import classNames from "classnames";

const Card = (props) => {
  const { title, className, children } = props;

  return (
    <div>
      {title && <h1 className="mb-1">{title}</h1>}
      <div
        className={classNames(
          "card p-3 block transition-transform duration-200 overflow-x-scroll min-h-48 min-w-48",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Card;
