import React, { useState, useEffect, createContext, useContext } from "react";

import server from "helper/server";

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

function AuthProvider(props) {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [logo, setLogo] = useState(null);
  const [mainTitle, setMainTitle] = useState("");

  const login = async ({ username, password }) => {
    setMessage(null);
    try {
      setLoginLoading(true);
      const response = await server.call({
        data: { username, password },
        url: "/users/login",
      });
      const data = response.data;
      setTimeout(function () {
        setLoginLoading(false);
      }, 1000);
      if (data) {
        if (data.data && data.success === true) {
          localStorage.setItem("InnoichiWebToken", data.data.token);
          localStorage.setItem("ActiveMenus", ["home"]);
          setUserData(data.data.loggedUser);
          setMessage(null);
          getTitle(data.data.token);
        } else {
          setUserData(null);
          let errMessage = "";
          if (response.data.type && response.data.type === "validation") {
            const errors = response.data.errors;
            if (errors && errors.length > 0) {
              errors.forEach((element) => {
                errMessage += element.message + `, <br />`;
              });
            }
            setMessage(errMessage);
          } else {
            setMessage(data.message);
          }
        }
      }
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log({ ex });
      setTimeout(function () {
        setLoginLoading(false);
      }, 1500);
      setUserData(null);
      setMessage("エラー");
    }
  };

  const logOut = async function () {
    try {
      await server.call({ url: "/users/logout" });
    } catch (ex) {
    } finally {
      setUserData(null);
      localStorage.removeItem("InnoichiWebToken");
      getTitle(null);
    }
  };

  const getTitle = async (token) => {
    setMainTitle("");
    let response = {};
    if (token) {
      response = await server.call({ url: "/site-info/get-company-name" });
    } else {
      response = await server.call({ url: "/site-info/detail" });
    }
    if (response.data && response.data.data && response.data.success) {
      setLogo(response.data.data.logo);
      setMainTitle(response.data.data.title);
    } else {
      setMainTitle("");
    }
  };

  const getUserData = async () => {
    setLoading(true);
    const response = await server.call({
      url: "/users/get-user-data",
    });

    if (response.status === 401) {
      localStorage.removeItem("InnoichiWebToken");
      setUserData(null);
    } else {
      const data = response.data;
      if (data && data.success === true) {
        setUserData(data.loggedUser);
      } else {
        localStorage.removeItem("InnoichiWebToken");
        setUserData(null);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("InnoichiWebToken");
    getTitle(token);
    if (token) {
      getUserData();
    } else {
      setUserData(null);
      setLoading(false);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        userData,
        login,
        logOut,
        loading,
        loginLoading,
        message,
        setMessage,
        logo,
        mainTitle,
      }}
      {...props}
    />
  );
}

export { AuthProvider, useAuth, AuthContext };
