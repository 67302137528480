import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

// redux
import { setShowImageDialog, setImageSrc } from "reducers/actions";

import Modal, { ModalHeader, ModalBody } from "components/Modal";

import helper from "helper/helper";

const ImageViewer = () => {
  const dispatch = useDispatch();

  const showImageDialog = useSelector((state) => state.custom.showImageDialog);
  const imageSrc = useSelector((state) => state.custom.imageSrc);

  return (
    <Modal
      active={showImageDialog}
      staticBackdrop
      onClose={() => {
        dispatch(setShowImageDialog(false));
        dispatch(setImageSrc(null));
      }}
    >
      <ModalHeader />
      <ModalBody className="m-0 p-0">
        <div className="min-h-[250px] w-1/2 max-sm:w-11/12">
          {helper.urlExists(imageSrc) ? (
            <img alt={imageSrc} src={imageSrc} />
          ) : (
            <div className="flex flex-row justify-center items-center pt-12">
              <div className="alert-wrapper w-1/3 max-sm:mx-auto max-sm:w-11/12">
                <div className="alert alert_danger">
                  ファイルが見つかりません
                </div>
              </div>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ImageViewer;
