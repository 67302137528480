import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// redux
import { setCategoryFilter } from "reducers/actions";

import { BaseInput, BaseSearchSelect, BaseTextarea } from "baseComponents";
import DragAndDropFile from "./DragAndDropFile";

import { useAuth } from "context/auth";

const Form = (props) => {
  const dispatch = useDispatch();

  const { userData } = useAuth();
  const role = userData ? userData.role : null;

  const documentsEditData = useSelector(
    (state) => state.documents.documentsEditData
  );
  const categoryFilter = useSelector((state) => state.documents.categoryFilter);

  const { changeModifyObject } = props;

  useEffect(() => {
    dispatch(setCategoryFilter(null));
    role !== "Admin" &&
      dispatch(setCategoryFilter(["apartment_id", "=", userData.apartment_id]));
  }, []);

  useEffect(() => {
    if (documentsEditData.id && documentsEditData.apartment_id) {
      dispatch(
        setCategoryFilter(["apartment_id", "=", documentsEditData.apartment_id])
      );
    }
  }, [documentsEditData]);

  const changeValue = (name, value) => {
    changeModifyObject && changeModifyObject({ [name]: value });

    if (name === "apartment_id") {
      dispatch(setCategoryFilter(["apartment_id", "=", value]));
      changeModifyObject &&
        changeModifyObject({ apartment_id: value, category_id: null });
    }
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-x-2 max-sm:grid-cols-1">
        <BaseSearchSelect
          label={"マンション"}
          name={"apartment_id"}
          value={
            role !== "Admin"
              ? userData.apartment_id || null
              : documentsEditData.apartment_id || null
          }
          disabled={role !== "Admin" ? true : false}
          placeholder={"マンション"}
          objectName={"apartments"}
          textField={"apartment_name"}
          changeValue={changeValue}
        />
        <BaseSearchSelect
          label={"カテゴリー"}
          name={"category_id"}
          value={documentsEditData.category_id || null}
          placeholder={"カテゴリー"}
          objectName={"categories"}
          textField={"category_name"}
          changeValue={changeValue}
          additionalFilter={categoryFilter || ["apartment_id", "=", null]}
        />
      </div>
      <div className="grid min-lg:grid-cols-1">
        <BaseInput
          label={"タイトル"}
          name={"title"}
          value={documentsEditData.title || ""}
          placeholder={"タイトル"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid min-lg:grid-cols-1 gap-x-3">
        <BaseTextarea
          label={"説明"}
          name={"description"}
          value={documentsEditData.description || ""}
          placeholder={"説明"}
          changeValue={changeValue}
          className="h-32"
        />
      </div>
      {/* <div className="grid grid-cols-1 max-sm:grid-cols-1">
        <FileUpload
          id={documentsEditData.id || null}
          name={"document_file"}
          changeValue={changeValue}
        />
      </div> */}
      <div className="grid grid-cols-1 max-sm:grid-cols-1">
        <DragAndDropFile
          id={documentsEditData.id || null}
          name={"document"}
          changeValue={changeValue}
        />
      </div>
    </>
  );
};

export default Form;
