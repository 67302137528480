import {
  SHOW_APARTMENTS_DIALOG,
  APARTMENTS_MODIFY_DATA,
  APARTMENTS_EDIT_DATA,
  APARTMENTS_LIST_DATA,
} from "reducers/actions/apartmentsTypes";

// Apartments Dialogs
export const setApartmentsDialog = (isActive) => {
  return { type: SHOW_APARTMENTS_DIALOG, payload: isActive };
};

// Apartments crud
export const setApartmentsModifyData = (data) => {
  return { type: APARTMENTS_MODIFY_DATA, payload: data };
};

export const setApartmentsEditData = (data) => {
  return { type: APARTMENTS_EDIT_DATA, payload: data };
};

export const setApartmentsList = (data) => {
  return { type: APARTMENTS_LIST_DATA, payload: data };
};
