import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { BaseGridLoading } from "baseComponents";
import { BackToHome } from "additionalComponents";

import CardGrid from "customComponents/Documents/CardGrid";
import CardWeekDays from "customComponents/Weather/CardWeekDays";

import helper from "helper/helper";
import server from "helper/server";

const Category = () => {
  const { categoryId } = useParams();

  const [loading, setLoading] = useState(false);
  const [documentsList, setDocumentsList] = useState({});

  useEffect(() => {
    getList();
  }, [categoryId]);

  const getList = async () => {
    if (categoryId) {
      setLoading(true);
      let newAlertData = { show: true, success: true, message: "" };

      let rootFilter = [
        ["status", "=", "active"],
        "and",
        ["category_id", "=", categoryId],
      ];
      let rootSort = null;
      let skip = 0;
      let take = 10;
      let sort = [{ selector: "id", desc: true }];

      let select = JSON.stringify([
        "*",
        { Apartment: ["id", "apartment_name"] },
        { Category: ["id", "category_name"] },
        { CreatedUser: ["id", "firstname"] },
        { UpdatedUser: ["id", "firstname"] },
      ]);

      let params = helper.getRequest(
        { skip, take, sort, filter: null },
        rootFilter,
        rootSort
      );
      params += `select=${select}&`;
      params = params.slice(0, -1);

      const response = await server.baseList({
        objectName: "documents",
        params,
      });

      if (response.data && response.data.data) {
        newAlertData.success = response.data.success;
        newAlertData.message = response.data.message;
        response.data.success && setDocumentsList(response.data.data);
      }

      setLoading(false);
    }
  };

  return (
    <>
      {/* Back to Home */}
      <BackToHome />
      {/* List */}
      <div className="relative min-h-[130px]">
        {loading && <BaseGridLoading />}
        <div className="flex flex-col gap-y-4">
          {documentsList.rows &&
            documentsList.rows.map((item, key) => {
              return (
                <CardGrid
                  key={key}
                  id={item.id}
                  category_name={
                    item.Category ? item.Category.category_name : null
                  }
                  title={item.title}
                  description={item.description}
                  file_name={item.file_name}
                  generated_name={item.generated_name}
                  views={item.views}
                  created_at={item.created_at}
                />
              );
            })}
        </div>
      </div>

      {categoryId === "7" && <CardWeekDays />}
    </>
  );
};

export default Category;
