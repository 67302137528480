import { Routes, Route, Navigate } from "react-router-dom";
import classNames from "classnames";

import routes from "routes.js";

import Tooltip from "components/Tooltip";

import useFonts from "reducers/hooks/useFonts";
import useGray from "reducers/hooks/useGray";
import useTheme from "reducers/hooks/useTheme";

import useFullscreen from "reducers/hooks/useFullScreen";

import { useAuth } from "context/auth";

const LayoutAuth2 = () => {
  const { mainTitle } = useAuth();

  const [isFullscreen, toggleFullscreen] = useFullscreen();

  useTheme();
  useGray();
  useFonts();

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className="w-full bg-transparent">
      {/* Top Bar */}
      <section className="top-bar">
        {/* Brand */}
        <div className="flex items-center mr-auto">
          {/* <img src={logo} alt="Logo" className="w-8 h-8 mr-1" /> */}
          <span className="brand">{mainTitle}</span>
        </div>

        <nav className="flex items-center ml-auto">
          {/* Fullscreen */}
          <Tooltip content="Fullscreen">
            <button
              className={classNames(
                "hidden min-lg:inline-block ml-3 px-2 text-2xl leading-none la",
                {
                  "la-compress-arrows-alt": isFullscreen,
                  "la-expand-arrows-alt": !isFullscreen,
                }
              )}
              onClick={toggleFullscreen}
            />
          </Tooltip>
        </nav>
      </section>
      <Routes>
        {getRoutes(routes)}
        <Route path="/*" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    </div>
  );
};

export default LayoutAuth2;
