import {
  SHOW_CONSTRUCTION_ITEMS_DIALOG,
  CONSTRUCTION_ITEMS_MODIFY_DATA,
  CONSTRUCTION_ITEMS_EDIT_DATA,
  CONSTRUCTION_ITEMS_LIST_DATA,
  CONSTRUCTION_ITEMS_DETAIL_LIST_DATA,
  ALL_TOTAL_PRICE,
  ALL_ROOM_TOTAL_PRICE,
} from "reducers/actions/constructionItemsTypes";

const initialState = {
  constructionItemsDialog: false,
  constructionItemsModifyData: {},
  constructionItemsEditData: {},
  constructionItemsListData: { rows: [], count: 0 },
  constructionItemsDetailListData: [],
  allTotalPrice: 0,
  allRoomTotalPrice: 0,
};

const ConstructionItems = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CONSTRUCTION_ITEMS_DIALOG:
      return { ...state, constructionItemsDialog: action.payload };

    case CONSTRUCTION_ITEMS_MODIFY_DATA:
      return { ...state, constructionItemsModifyData: action.payload };

    case CONSTRUCTION_ITEMS_EDIT_DATA:
      return { ...state, constructionItemsEditData: action.payload };

    case CONSTRUCTION_ITEMS_LIST_DATA:
      return { ...state, constructionItemsListData: action.payload };

    case CONSTRUCTION_ITEMS_DETAIL_LIST_DATA:
      return { ...state, constructionItemsDetailListData: action.payload };

    case ALL_TOTAL_PRICE:
      return { ...state, allTotalPrice: action.payload };

    case ALL_ROOM_TOTAL_PRICE:
      return { ...state, allRoomTotalPrice: action.payload };

    default:
      return state;
  }
};

export default ConstructionItems;
