import { useDispatch } from "react-redux";

import { setAlert } from "reducers/actions";

import server from "helper/server";

import { useAuth } from "context/auth";

const useCategoryDelete = () => {
  const { userData } = useAuth();

  const dispatch = useDispatch();

  const deleteCategory = async (id, callback) => {
    let newAlertData = { show: true };

    const role = userData ? userData.role : null;
    if (role !== "Admin" && role !== "Creator") {
      dispatch(
        setAlert({
          show: true,
          success: false,
          message: "管理者権限を持つユーザーではありません",
        })
      );
      return;
    }

    if (id) {
      const response = await server.baseDelete({
        objectName: "categories",
        id,
      });
      if (response.data) {
        let errMessage = "";
        newAlertData.success = response.data.success;
        if (response.data.type && response.data.type === "validation") {
          const errors = response.data.errors;
          if (errors && errors.length > 0) {
            errors.forEach((element) => {
              errMessage += element.message + `, <br />`;
            });
          }
          newAlertData.message = errMessage;
        } else {
          newAlertData.message = response.data.message;
        }
        response.data.success && callback && callback();
      } else {
        newAlertData.success = false;
        newAlertData.message = "エラー";
      }
    } else {
      newAlertData.success = false;
      newAlertData.message = "テーブルが見つかりません";
    }
    dispatch(setAlert(newAlertData));
  };

  return [deleteCategory];
};

export default useCategoryDelete;
