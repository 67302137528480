import { useState } from "react";
import { useDispatch } from "react-redux";

import { setAlert } from "reducers/actions";

import { BaseInput, BaseLoadButton } from "baseComponents";

import ExcelUpload from "customComponents/ExcelImport/ExcelUpload";

import server from "helper/server";

const ExcelImport = (props) => {
  const dispatch = useDispatch();

  const { id } = props;

  const [file, setFile] = useState([]);
  const [title, setTitle] = useState(null);
  const [data, setData] = useState([]);

  const save = async () => {
    let newAlertData = { show: true, success: true, message: "" };
    if (file.length > 0 && file[0] && file[0].file) {
      const response = await server.baseSaveWithFile({
        url: "/excel-import/import",
        data: { title },
        files: file,
      });

      if (response.data) {
        newAlertData.success = response.data.success;
        let errMessage = "";
        if (response.data.type && response.data.type === "validation") {
          const errors = response.data.errors;
          if (errors && errors.length > 0) {
            errors.forEach((element) => {
              errMessage += element.message + `, <br />`;
            });
          }
          newAlertData.message = errMessage;
        } else {
          newAlertData.message = response.data.message;
        }

        if (response.data.success) {
          setData(response.data.data);
          setFile([]);
          setTitle(null);
        }
      } else {
        newAlertData.success = false;
        newAlertData.message = "エラー";
      }

      dispatch(setAlert(newAlertData));
      return;
    } else {
      newAlertData.success = false;
      newAlertData.message = "ファイルを選ぶ";
      dispatch(setAlert(newAlertData));
      return;
    }
  };

  return (
    <div className="grid grid-cols-1 min-md:grid-cols-2 gap-4">
      <div>
        <div className="grid min-lg:grid-cols-1">
          <ExcelUpload
            id={id}
            name={"excel"}
            valueFile={file.length > 0 ? file[0] : null}
            changeValue={(name, value) => {
              setFile(value);
              setTitle(value[0].fileInfo.original_name);
            }}
          />
        </div>
        {/* <div className="grid mt-4 min-lg:grid-cols-1">
          <BaseInput
            label={"タイトル"}
            name={"title"}
            value={title || ""}
            placeholder={"タイトル"}
            changeValue={(name, value) => setTitle(value)}
          />
        </div> */}
        <div className="grid mt-4 min-lg:grid-cols-1">
          <BaseLoadButton
            className="w-32 max-sm:p-1 flex justify-center"
            icon="paper-plane"
            outlined={true}
            onClick={async () => await save()}
          >
            <span className="text-lg max-sm:text-sm">Send</span>
          </BaseLoadButton>
        </div>
      </div>
      <div className="border rounded-lg">
        <h4 className="py-2 px-4 uppercase border-b text-base">
          Imported data
        </h4>
        <div className="p-2 max-h-96 overflow-y-auto">
          {data && data.length > 0 && (
            <div className="table-provider">
              <table className="table table-auto table_hoverable">
                <thead>
                  <tr>
                    <th className="text-left uppercase">No.</th>
                    <th className="text-left uppercase">お名前</th>
                    <th className="text-left uppercase">年齢</th>
                    <th className="text-left uppercase">性別</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>{item.col1}</td>
                        <td>{item.col2}</td>
                        <td>{item.col3}</td>
                        <td>{item.col4}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExcelImport;
