import {
  SHOW_INQUIRIES_DIALOG,
  INQUIRIES_MODIFY_DATA,
  INQUIRIES_EDIT_DATA,
  INQUIRIES_LIST_DATA,
  INQUIRIES_GRID_DATA,
  INQUIRY_REPLY_DIALOG,
  INQUIRY_COUNT,
} from "reducers/actions/inquiriesTypes";

const initialState = {
  inquiriesDialog: false,
  inquiriesModifyData: {},
  inquiriesEditData: {},
  inquiriesListData: { rows: [], count: 0 },
  inquiriesGridData: { rows: [], count: 0 },
  activeInquiryReplyDialog: false,
  inquiryCount: 0,
};

const Inquiries = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_INQUIRIES_DIALOG:
      return { ...state, inquiriesDialog: action.payload };

    case INQUIRIES_MODIFY_DATA:
      return { ...state, inquiriesModifyData: action.payload };

    case INQUIRIES_EDIT_DATA:
      return { ...state, inquiriesEditData: action.payload };

    case INQUIRIES_LIST_DATA:
      return { ...state, inquiriesListData: action.payload };

    case INQUIRIES_GRID_DATA:
      return { ...state, inquiriesGridData: action.payload };

    case INQUIRY_REPLY_DIALOG:
      return { ...state, activeInquiryReplyDialog: action.payload };

    case INQUIRY_COUNT:
      return { ...state, inquiryCount: action.payload };

    default:
      return state;
  }
};

export default Inquiries;
