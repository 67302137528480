// Show dialog
export const SHOW_CATEGORIES_DIALOG = "SHOW_CATEGORIES_DIALOG";

// CRUD
export const CATEGORIES_MODIFY_DATA = "CATEGORIES_MODIFY_DATA";

export const CATEGORIES_EDIT_DATA = "CATEGORIES_EDIT_DATA";

export const CATEGORIES_LIST_DATA = "CATEGORIES_LIST_DATA";

export const CATEGORY_COUNT = "CATEGORY_COUNT";
