import { Iterable } from "immutable";
import {
  configureStore,
  createSerializableStateInvariantMiddleware,
  isPlain,
} from "@reduxjs/toolkit";

import rootReducer from "reducers";

// Augment middleware to consider Immutable.JS iterables serializable
const isSerializable = (value) => Iterable.isIterable(value) || isPlain(value);

const getEntries = (value) =>
  Iterable.isIterable(value) ? value.entries() : Object.entries(value);

const store = configureStore({
  reducer: rootReducer,
  middleware: [
    createSerializableStateInvariantMiddleware({
      isSerializable,
      getEntries,
    }),
  ],
});

export default store;
