import { useDispatch } from "react-redux";
import { setInquiryCount } from "reducers/actions";

import server from "helper/server";

const useInquiryCount = () => {
  const dispatch = useDispatch();

  const getInquiryCount = async () => {
    const response = await server.call({
      url: "/inquiries/menu-count",
    });

    if (response.data && response.data.success) {
      dispatch(setInquiryCount(response.data.count));
    }
  };

  return getInquiryCount;
};

export default useInquiryCount;
