import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// redux
import {
  setAlert,
  setShowGridLoading,
  setConstructionDetailsDialog,
  setConstructionDetailsList,
  setConstructionDetailsModifyData,
  setConstructionDetailsEditData,
} from "reducers/actions";

import useConstructionDetailsSave from "reducers/hooks/useConstructionDetailsSave";
import useConstructionDetailDelete from "reducers/hooks/useConstructionDetailDelete";

// components
import Tooltip from "components/Tooltip";
import Button from "components/Button";
import { BaseDialog } from "baseComponents";
import Grid from "./Grid";
import Form from "./Form";

import { useAuth } from "context/auth";

import server from "helper/server";
import helper from "helper/helper";

const ConstructionDetails = () => {
  const dispatch = useDispatch();

  const { userData } = useAuth();
  const role = userData ? userData.role : null;

  const [detailsDialog, setDetailsDialog] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [filter, setFilter] = useState(null);

  const constructionDetailsDialog = useSelector(
    (state) => state.constructionDetails.constructionDetailsDialog
  );
  const constructionDetailsModifyData = useSelector(
    (state) => state.constructionDetails.constructionDetailsModifyData
  );
  const constructionDetailsEditData = useSelector(
    (state) => state.constructionDetails.constructionDetailsEditData
  );

  const [saveConstructionDetails] = useConstructionDetailsSave();
  const [deleteConstructionDetail] = useConstructionDetailDelete();

  useEffect(() => {
    getList();
  }, [page, size, filter]);

  const getList = async () => {
    dispatch(setShowGridLoading(true));

    let rootFilter = ["status", "=", "active"];
    let rootSort = null;
    let skip = (page - 1) * size;
    let take = size;
    let sort = [{ selector: "id", desc: true }];

    let select = JSON.stringify([
      "*",
      { Apartment: ["id", "apartment_name"] },
      { CreatedUser: ["id", "firstname"] },
      { UpdatedUser: ["id", "firstname"] },
    ]);
    let params = helper.getRequest(
      { skip, take, sort, filter },
      rootFilter,
      rootSort
    );
    params += `select=${select}&`;
    params = params.slice(0, -1);

    const response = await server.baseList({
      objectName: "constructionDetails",
      params,
    });

    if (response.data && response.data.data) {
      if (response.data.success === false) {
        dispatch(setAlert({ show: true, success: false, message: "間違い" }));
      }
      response.data.success &&
        dispatch(setConstructionDetailsList(response.data.data));
    }

    dispatch(setShowGridLoading(false));
  };

  return (
    <>
      <BaseDialog
        title={"工事内容"}
        isActive={constructionDetailsDialog}
        hide={() => {
          dispatch(setAlert({ show: false }));
          dispatch(setConstructionDetailsModifyData({}));
          dispatch(setConstructionDetailsEditData({}));
          dispatch(setConstructionDetailsDialog(false));
        }}
        save={() =>
          saveConstructionDetails(
            constructionDetailsModifyData,
            constructionDetailsEditData,
            () => {
              dispatch(setConstructionDetailsModifyData({}));
              dispatch(setConstructionDetailsEditData({}));
              dispatch(setConstructionDetailsDialog(false));
              getList();
            }
          )
        }
        width={"min-lg:w-[420px]"}
      >
        <Form
          changeModifyObject={(data) =>
            dispatch(
              setConstructionDetailsModifyData({
                ...constructionDetailsModifyData,
                ...data,
              })
            )
          }
        />
      </BaseDialog>
      {/* Detail Dialog */}
      <BaseDialog
        title={"工事内容"}
        isActive={detailsDialog}
        hide={() => {
          dispatch(setAlert({ show: false }));
          dispatch(setConstructionDetailsModifyData({}));
          dispatch(setConstructionDetailsEditData({}));
          setDetailsDialog(false);
        }}
        width={"min-lg:w-[420px]"}
        hideSave={true}
      >
        <Form
          changeModifyObject={(data) =>
            dispatch(
              setConstructionDetailsModifyData({
                ...constructionDetailsModifyData,
                ...data,
              })
            )
          }
          readOnly={true}
        />
      </BaseDialog>
      <div className="w-full">
        {/* custom actions */}
        <section className="flex items-start w-full gap-2 mb-3">
          {/* Refresh */}
          <Tooltip content={"リフレッシュ"} placement="bottom">
            <Button icon color="info" onClick={() => getList()}>
              <span className="la la-sync" />
            </Button>
          </Tooltip>
          {/* Add New */}
          {role === "Admin" && (
            <Tooltip content={"新しい"} placement="bottom">
              <Button
                icon
                color="success"
                onClick={() => dispatch(setConstructionDetailsDialog(true))}
              >
                <span className="la la-plus" />
              </Button>
            </Tooltip>
          )}
        </section>
        <Grid
          edit={(data) => {
            dispatch(setConstructionDetailsDialog(true));
            dispatch(
              setConstructionDetailsEditData(
                Object.assign(constructionDetailsEditData, data)
              )
            );
          }}
          destroy={(id) => deleteConstructionDetail(id, () => getList())}
          detail={(data) => {
            setDetailsDialog(true);
            dispatch(
              setConstructionDetailsEditData(
                Object.assign(constructionDetailsEditData, data)
              )
            );
          }}
          onFilterChange={(field, value) =>
            setFilter(value ? [field, "=", value] : null)
          }
          onPageChange={(page) => setPage(page)}
          onPageSizeChange={(size) => setSize(size)}
        />
      </div>
    </>
  );
};

export default ConstructionDetails;
