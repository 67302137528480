import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// redux
import {
  setAlert,
  setShowGridLoading,
  setUsersDialog,
  setUsersList,
  setUsersModifyData,
  setUsersEditData,
} from "reducers/actions";

import useUsersSave from "reducers/hooks/useUsersSave";
import useUserDelete from "reducers/hooks/useUserDelete";

// components
import Tooltip from "components/Tooltip";
import Button from "components/Button";

import { BaseDialog } from "baseComponents";

import Grid from "./Grid";
import Form from "./Form";

import server from "helper/server";
import helper from "helper/helper";

const Users = () => {
  const dispatch = useDispatch();

  const usersDialog = useSelector((state) => state.users.usersDialog);
  const usersModifyData = useSelector((state) => state.users.usersModifyData);
  const usersEditData = useSelector((state) => state.users.usersEditData);

  const [saveUsers] = useUsersSave();
  const [deleteUser] = useUserDelete();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  useEffect(() => {
    getList();
  }, [page, size]);

  const getList = async () => {
    dispatch(setShowGridLoading(true));

    let rootFilter = ["status", "=", "active"];
    let skip = (page - 1) * size;
    let take = size;
    let sort = [{ selector: "id", desc: true }];

    let select = JSON.stringify([
      "*",
      { Apartment: ["id", "apartment_name"] },
      { CreatedUser: ["id", "firstname"] },
      { UpdatedUser: ["id", "firstname"] },
    ]);
    let params = helper.getRequest({ skip, take, sort }, rootFilter, null);
    params += `select=${select}&`;
    params = params.slice(0, -1);

    const response = await server.baseList({
      objectName: "users",
      params,
    });

    if (response.data && response.data.data) {
      if (response.data.success === false) {
        dispatch(setAlert({ show: true, success: false, message: "間違い" }));
      }
      response.data.success && dispatch(setUsersList(response.data.data));
    }

    dispatch(setShowGridLoading(false));
  };

  return (
    <>
      <BaseDialog
        title={"ユーザー"}
        isActive={usersDialog}
        hide={() => {
          dispatch(setAlert({ show: false }));
          dispatch(setUsersModifyData({}));
          dispatch(setUsersEditData({}));
          dispatch(setUsersDialog(false));
        }}
        save={() =>
          saveUsers(usersModifyData, usersEditData, () => {
            dispatch(setUsersModifyData({}));
            dispatch(setUsersEditData({}));
            dispatch(setUsersDialog(false));
            getList();
          })
        }
      >
        <Form
          changeModifyObject={(data) =>
            dispatch(setUsersModifyData({ ...usersModifyData, ...data }))
          }
        />
      </BaseDialog>
      <div className="w-full">
        {/* custom actions */}
        <section className="flex items-start w-full gap-2 mb-3">
          {/* Refresh */}
          <Tooltip content={"リフレッシュ"} placement="bottom">
            <Button icon color="info" onClick={() => getList()}>
              <span className="la la-sync" />
            </Button>
          </Tooltip>
          {/* Add New */}
          <Tooltip content={"新しい"} placement="bottom">
            <Button
              icon
              color="success"
              onClick={() => dispatch(setUsersDialog(true))}
            >
              <span className="la la-plus" />
            </Button>
          </Tooltip>
        </section>
        <Grid
          edit={(data) => {
            dispatch(setUsersDialog(true));
            dispatch(setUsersEditData(Object.assign(usersEditData, data)));
          }}
          destroy={(id) => deleteUser(id, () => getList())}
          onPageChange={(page) => setPage(page)}
          onPageSizeChange={(size) => setSize(size)}
        />
      </div>
    </>
  );
};

export default Users;
