import { BaseCard } from "baseComponents";
import { Users } from "customComponents";

const UsersPage = () => {
  return (
    <BaseCard title={"ユーザー"}>
      <Users />
    </BaseCard>
  );
};

export default UsersPage;
