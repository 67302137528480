import { BaseCard } from "baseComponents";
import { Documents } from "customComponents";

const DocumentsPage = () => {
  return (
    <BaseCard title={"ファイルアップロード"}>
      <Documents />
    </BaseCard>
  );
};

export default DocumentsPage;
