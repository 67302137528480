import { BaseCard } from "baseComponents";

import RoomDetail from "customComponents/Constructions/RoomDetail";

const ConstructionsRoomDetail = () => {
  return (
    <BaseCard>
      <RoomDetail />
    </BaseCard>
  );
};

export default ConstructionsRoomDetail;
