import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import classNames from "classnames";
import PropTypes from "prop-types";
import moment from "moment";

import { setAlert, setShowPdfDialog, setPdfSrc } from "reducers/actions";

import useCategoryCount from "reducers/hooks/useCategoryCount";

import Tooltip from "components/Tooltip";

import { useAuth } from "context/auth";

import helper from "helper/helper";
import server from "helper/server";
import { setJapanDate } from "helper/helper";

import pdfImg from "assets/file-pdf-solid-240.png";

const CardGrid = (props) => {
  const { userData } = useAuth();
  const dispatch = useDispatch();

  const {
    id,
    title,
    description,
    file_name,
    generated_name,
    views,
    created_at,
  } = props;

  const [isNew, setIsNew] = useState(false);
  const [isMore, setIsMore] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const getCategoryCount = useCategoryCount();

  useEffect(() => {
    checkDocument();
  }, [id]);

  const checkDocument = async () => {
    const response = await server.call({
      url: "/notifications/check-unread-document",
      data: { document_id: id },
    });
    response.data && setIsNew(response.data.data);
  };

  // const userRead = async () => {
  //   const response = await server.call({
  //     url: "/notifications/user-read",
  //     data: { document_id: id },
  //   });
  //   response.data && response.data.success && setIsNew(false);
  // };

  const showPdf = async () => {
    setShowLoading(true);
    // await userRead();
    if (generated_name) {
      const src = server.fileURL + "/documents/" + generated_name;
      if (helper.urlExists(src)) {
        dispatch(setShowPdfDialog(true));
        dispatch(setPdfSrc(src));
        getCategoryCount();
      } else {
        dispatch(
          setAlert({
            show: true,
            success: false,
            message: "ファイルが見つかりません",
          })
        );
      }
    }
    setShowLoading(false);
  };

  const showAndHideMore = async () => {
    setIsMore(!isMore);
    // !isMore && !generated_name && userRead();
  };

  return (
    <div className="card card_row px-5 relative pt-4 mt-2">
      {isNew && (
        <div className="absolute -top-1 left-2 h-[20px] flex items-center ">
          <div className="py-1 px-2 rounded-full bg-success font-extrabold text-white dark:text-gray-300 text-xs uppercase mr-3 z-20">
            New
          </div>
          <div className="py-1 rounded-full bg-white dark:bg-gray-700 font-medium shadow-lg shadow-gray-300 dark:shadow-gray-800 text-red-700 dark:text-red-600 text-xs uppercase -ml-8 px-8 z-10">
            {setJapanDate(moment(created_at).format())}
          </div>
        </div>
      )}
      <div className="flex flex-col justify-start pr-3">
        <div className="w-full mt-4 flex flex-row justify-start">
          <div>
            <h5 className="text-gray-700 dark:text-gray-300">{title}</h5>
            <p className="text-gray-600 dark:text-gray-200 mb-2">
              {description && (
                <>
                  <span className="mr-12">
                    {isMore ? description : description.substring(0, 100)}
                    {!isMore && description.length >= 100 ? "..." : ""}
                  </span>
                </>
              )}
              {((description && description.length >= 100) ||
                !generated_name) && (
                <span
                  onClick={showAndHideMore}
                  className="text-sm hover:underline hover:text-blue-500 hover:dark:text-indigo-300 cursor-pointer"
                >
                  {isMore ? "閉じる" : "開く"}
                </span>
              )}
            </p>
          </div>
        </div>
        {generated_name && (
          <div className="w-full mt-3">
            <div className="relative flex mb-3 bg-[#f5f8ff] dark:bg-gray-700 p-2 rounded-xl">
              <div className="relative mr-1 rounded-xl w-12 cursor-pointer">
                {showLoading && (
                  <div className="absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center">
                    <div className="opacity-20 bg-black dark:bg-white rounded-xl absolute top-0 right-0 bottom-0 left-0"></div>
                    <span className="las la-spinner animate-spin text-2xl text-gray-600 dark:text-gray-300" />
                  </div>
                )}
                <Tooltip content={"ビュー"} placement="bottom">
                  <img
                    src={pdfImg}
                    alt={generated_name}
                    className="min-w-[44px]"
                    onClick={showPdf}
                  />
                </Tooltip>
              </div>
              <div className="flex flex-col justify-start items-center">
                <h5 className="font-semibold dark:text-gray-300 break-all">
                  {file_name}
                </h5>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="body">
        <div className="h-full grid grid-cols-1 max-md:grid-cols-2 min-md:grid-rows-2">
          <div>
            {userData.role === "Admin" && (
              <div>
                <h6 className="uppercase">ビュー</h6>
                <p>{views}</p>
              </div>
            )}
          </div>
          <div>
            <h6 className="uppercase">作成日</h6>
            <p>
              {setJapanDate(moment(created_at).format())}
              {/* {setJapanDate(null)} */}

              {/* <span className={classNames({ "text-red-700": isNew })}/> */}
            </p>
          </div>
        </div>
      </div>
      {/* {userData.role === "Admin" && (
        <div className="actions">
          <Button className="btn btn-icon btn_outlined btn_secondary mt-auto ml-auto min-lg:ml-0">
            <span className="la la-pen-fancy"/>
          </Button>
          <Button className="btn btn-icon btn_outlined btn_danger min-lg:mt-2 ml-2 min-lg:ml-0">
            <span className="la la-trash-alt"/>
          </Button>
        </div>
      )} */}
    </div>
  );
};

CardGrid.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  file_name: PropTypes.string,
  generated_name: PropTypes.string,
  views: PropTypes.number,
  created_at: PropTypes.string,
};

export default CardGrid;
