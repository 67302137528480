import {
  SHOW_ALERT,
  SHOW_GRID_LOADING,
  SHOW_CONFIRM_DIALOG,
  SHOW_CONFIRM_DATA,
  SHOW_IMAGE_DIALOG,
  SHOW_PDF_DIALOG,
  PDF_SRC,
  IMAGE_SRC,
} from "reducers/actions/customTypes";

const initialState = {
  alertData: { show: false },
  showGridLoading: false,
  showConfirmDialog: false,
  showConfirmData: { show: false, message: "" },
  showImageDialog: false,
  showPdfDialog: false,
  pdfSrc: null,
  imageSrc: null,
};

const CustomReducers = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return { ...state, alertData: action.payload };

    case SHOW_GRID_LOADING:
      return { ...state, showGridLoading: action.payload };

    case SHOW_CONFIRM_DIALOG:
      return { ...state, showConfirmDialog: action.payload };

    case SHOW_CONFIRM_DATA:
      return { ...state, showConfirmData: action.payload };

    case SHOW_IMAGE_DIALOG:
      return { ...state, showImageDialog: action.payload };

    case SHOW_PDF_DIALOG:
      return { ...state, showPdfDialog: action.payload };

    case IMAGE_SRC:
      return { ...state, imageSrc: action.payload };

    case PDF_SRC:
      return { ...state, pdfSrc: action.payload };

    default:
      return state;
  }
};

export default CustomReducers;
