import React, { useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import Button from "components/Button";
import Dropdown from "components/Dropdown";

import usePagination, { dots } from "reducers/hooks/usePagination";

const Pagination = (props) => {
  const {
    currentPage,
    totalCount = 0,
    siblingCount = 1,
    showFirst = false,
    showLast = false,
    size,
    className,
    onPageChange,
    onPageSizeChange,
  } = props;

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const paginationRange = usePagination({
    totalCount,
    size,
    siblingCount,
    currentPage,
  });

  // if (currentPage === 0 || paginationRange.length < 2) {
  //   return null;
  // }

  const lastPage = paginationRange[paginationRange.length - 1];

  const sizes = [10, 50, 100];

  const changePage = (page) => {
    onPageChange && onPageChange(page);
  };

  const changePageSize = (size) => {
    onPageSizeChange && onPageSizeChange(size);
  };

  const next = () => {
    onPageChange && onPageChange(currentPage + 1);
  };

  const previous = () => {
    onPageChange && onPageChange(currentPage - 1);
  };

  return (
    <div className={classNames("min-lg:flex", className)}>
      <nav className="flex items-center flex-wrap gap-1 p-2">
        <Button
          color="secondary"
          disabled={currentPage === 1}
          onClick={previous}
        >
          前
        </Button>
        {showFirst ? (
          <Button
            color={currentPage === 1 ? "primary" : "secondary"}
            onClick={() => changePage(1)}
          >
            初め
          </Button>
        ) : null}
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === dots) {
            return <span key={index} className="la la-ellipsis-h text-2xl" />;
          }

          return (
            <Button
              key={index}
              color={pageNumber === currentPage ? "primary" : "secondary"}
              outlined={pageNumber === currentPage ? false : true}
              onClick={() => changePage(pageNumber)}
            >
              {pageNumber}
            </Button>
          );
        })}
        {showLast ? (
          <Button
            color={currentPage === lastPage ? "primary" : "secondary"}
            onClick={() => changePage(lastPage)}
          >
            最後
          </Button>
        ) : null}
        <Button
          color="secondary"
          disabled={currentPage === lastPage}
          onClick={next}
        >
          次
        </Button>
      </nav>
      <div className="flex items-center ml-auto p-2 border-t min-lg:border-t-0 border-divider">
        {totalCount} 件中 {(currentPage - 1) * size + 1} ～{" "}
        {totalCount > currentPage * size ? currentPage * size : totalCount}{" "}
        件を表示
      </div>
      {sizes.length > 1 && (
        <div className="flex items-center gap-1 p-2 border-t min-lg:border-t-0 min-lg:border-l border-divider">
          <span>表示</span>
          <Dropdown
            content={
              <div className="dropdown-menu">
                {sizes.map((size) => (
                  <button
                    key={size}
                    onClick={() => {
                      setDropdownVisible(false);
                      changePageSize(size);
                    }}
                  >
                    {size}
                  </button>
                ))}
              </div>
            }
            visible={dropdownVisible}
            onClickOutside={() => setDropdownVisible(false)}
          >
            <Button
              color="secondary"
              outlined
              className="uppercase"
              onClick={() =>
                dropdownVisible
                  ? setDropdownVisible(false)
                  : setDropdownVisible(true)
              }
            >
              {size}
              <span className="ml-3 la la-caret-down text-xl leading-none" />
            </Button>
          </Dropdown>
          <span>アイテム</span>
        </div>
      )}
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
  siblingCount: PropTypes.number,
  showFirst: PropTypes.bool,
  showLast: PropTypes.bool,
  size: PropTypes.number,
  className: PropTypes.string,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default Pagination;
