import {
  SHOW_CONSTRUCTIONS_DIALOG,
  CONSTRUCTIONS_MODIFY_DATA,
  CONSTRUCTIONS_EDIT_DATA,
  CONSTRUCTIONS_LIST_DATA,
  CONSTRUCTIONS_GRID_DATA,
  CONSTRUCTIONS_ROOM_GRID_DATA,
  CONSTRUCTIONS_ROOM_DETAIL_LIST_DATA,
  CONSTRUCTION_COUNT,
} from "reducers/actions/constructionsTypes";

const initialState = {
  constructionsDialog: false,
  constructionsModifyData: {},
  constructionsEditData: {},
  constructionsListData: { rows: [], count: 0 },
  constructionsGridData: { rows: [], count: 0 },
  constructionsRoomGridData: [],
  constructionsRoomDetailListData: [],
  constructionCount: 0,
};

const Constructions = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CONSTRUCTIONS_DIALOG:
      return { ...state, constructionsDialog: action.payload };

    case CONSTRUCTIONS_MODIFY_DATA:
      return { ...state, constructionsModifyData: action.payload };

    case CONSTRUCTIONS_EDIT_DATA:
      return { ...state, constructionsEditData: action.payload };

    case CONSTRUCTIONS_LIST_DATA:
      return { ...state, constructionsListData: action.payload };

    case CONSTRUCTIONS_GRID_DATA:
      return { ...state, constructionsGridData: action.payload };

    case CONSTRUCTIONS_ROOM_GRID_DATA:
      return { ...state, constructionsRoomGridData: action.payload };

    case CONSTRUCTIONS_ROOM_DETAIL_LIST_DATA:
      return { ...state, constructionsRoomDetailListData: action.payload };

    case CONSTRUCTION_COUNT:
      return { ...state, constructionCount: action.payload };

    default:
      return state;
  }
};

export default Constructions;
