import {
  SHOW_USERS_DIALOG,
  SHOW_CHANGE_PASSWORD_DIALOG,
  USERS_MODIFY_DATA,
  USERS_EDIT_DATA,
  USERS_LIST_DATA,
  USERS_CHANGE_PASSWORD_DATA,
} from "reducers/actions/usersTypes";

// Users Dialogs
export const setUsersDialog = (isActive) => {
  return { type: SHOW_USERS_DIALOG, payload: isActive };
};

export const setChangePasswordDialog = (isActive) => {
  return { type: SHOW_CHANGE_PASSWORD_DIALOG, payload: isActive };
};

// Users crud
export const setUsersModifyData = (data) => {
  return { type: USERS_MODIFY_DATA, payload: data };
};

export const setUsersEditData = (data) => {
  return { type: USERS_EDIT_DATA, payload: data };
};

export const setUsersList = (data) => {
  return { type: USERS_LIST_DATA, payload: data };
};

// change password
export const setChangePasswordData = (data) => {
  return { type: USERS_CHANGE_PASSWORD_DATA, payload: data };
};
