import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Pdf viewer
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

// redux
import { setShowPdfDialog, setPdfSrc } from "reducers/actions";

import Modal, { ModalHeader, ModalBody } from "components/Modal";

import helper from "helper/helper";

const PdfViewer = () => {
  const dispatch = useDispatch();

  let defaultLayoutPluginInstance = defaultLayoutPlugin();

  const showPdfDialog = useSelector((state) => state.custom.showPdfDialog);
  const pdfSrc = useSelector((state) => state.custom.pdfSrc);

  return (
    <Modal
      active={showPdfDialog}
      staticBackdrop
      onClose={() => {
        dispatch(setShowPdfDialog(false));
        dispatch(setPdfSrc(null));
      }}
    >
      <ModalHeader />
      <ModalBody className="m-0 p-0">
        <div className="min-h-[250px] w-1/2 max-sm:w-11/12">
          {helper.urlExists(pdfSrc) ? (
            // <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
              <Viewer
                fileUrl={pdfSrc}
                plugins={[defaultLayoutPluginInstance]}
              />
            </Worker>
          ) : (
            <div className="flex flex-row justify-center items-center pt-12">
              <div className="alert-wrapper w-1/3 max-sm:mx-auto max-sm:w-11/12">
                <div className="alert alert_danger">
                  ファイルが見つかりません
                </div>
              </div>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PdfViewer;
