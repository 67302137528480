import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import useCategoryCount from "reducers/hooks/useCategoryCount";

import SubHeaderTitle from "additionalComponents/SubHeaderTitle";

import helper from "helper/helper";
import server from "helper/server";
import { BaseGridLoading } from "baseComponents";

const CustomGrid = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const categoryCount = useSelector((state) => state.categories.categoryCount);

  const [categoriesList, setCategoriesList] = useState([]);

  const getCategoryCount = useCategoryCount();

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    setLoading(true);
    let newAlertData = { show: true, success: true, message: "" };

    let rootFilter = ["status", "=", "active"];
    let rootSort = null;
    let skip = 0;
    let take = 100;
    let sort = [{ selector: "id", asc: true }];

    let select = JSON.stringify([
      "*",
      { Apartment: ["id", "apartment_name"] },
      { CreatedUser: ["id", "firstname"] },
      { UpdatedUser: ["id", "firstname"] },
    ]);

    let params = helper.getRequest(
      { skip, take, sort, filter: null },
      rootFilter,
      rootSort
    );
    params += `select=${select}&`;
    params = params.slice(0, -1);

    const response = await server.baseList({
      objectName: "categories",
      params,
    });

    if (response.data && response.data.data) {
      newAlertData.success = response.data.success;
      newAlertData.message = response.data.message;
      if (response.data.success) {
        setCategoriesList(response.data.data.rows);
        // category count service
        await getCategoryCount();
      }
    }

    setLoading(false);
  };

  return (
    <>
      {/* New header */}
      {loading && (
        <div className="relative max-w-72 min-h-48">
          <BaseGridLoading />
        </div>
      )}
      {categoriesList.length > 0 && (
        <>
          <SubHeaderTitle>[お知らせ]</SubHeaderTitle>
          <div className="grid grid-cols-4 lg:grid-cols-3 md:grid-cols-2 max-sm:grid-cols-1 gap-2">
            {categoriesList.map((category, key) => {
              let count = 0;
              let findCategory = categoryCount.filter(
                (s) => s.category_id === category.id
              );
              findCategory = findCategory.length > 0 ? findCategory[0] : null;
              count = findCategory ? findCategory.count : 0;
              return (
                <Link
                  key={"Link-" + key}
                  className="cursor-pointer flex flex-row justify-between items-center px-4 py-3 shadow bg-foreground dark:bg-gray-600 rounded-xl w-full text-gray-700 dark:text-gray-700 hover:text-blue-800 hover:bg-gray-100 dark:hover:bg-gray-700"
                  to={"/admin/category/" + category.id}
                >
                  <div className="flex flex-col mb-2 w-full">
                    <span className="text-xl ">{category.category_name}</span>
                    <span className="text-base text-gray-500 dark:text-gray-800">
                      {category.Apartment
                        ? category.Apartment.apartment_name
                        : null}
                    </span>
                  </div>
                  {count > 0 && (
                    <div className="py-0.5 px-1.5 border border-red-600 text-red-600 text-xs rounded-full ml-4">
                      {count}
                    </div>
                  )}
                </Link>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default CustomGrid;
