import { useDispatch } from "react-redux";

import { setAlert } from "reducers/actions";

import server from "helper/server";

import { useAuth } from "context/auth";

const useInquiriesSave = () => {
  const { userData } = useAuth();

  const dispatch = useDispatch();

  const getFiles = (data, key) => {
    return data[key] ? data[key] : null;
  };

  const getWithoutFile = (data = {}, key) => {
    let tempData = Object.assign({}, data);
    Object.keys(tempData).forEach((e, index) => {
      if (e === key) {
        delete tempData[e];
      }
    });
    return tempData;
  };

  const saveInquiries = async (modifyData, editData, callback) => {
    let response = {};
    let newAlertData = { show: true, success: true, message: "" };

    const role = userData ? userData.role : null;
    const userId = userData ? userData.id : null;
    if (role !== "Customer") {
      dispatch(
        setAlert({
          show: true,
          success: false,
          message: "権限がありません。",
        })
      );
      return;
    }

    if (editData.id && userId !== editData.created_by) {
      dispatch(
        setAlert({
          show: true,
          success: false,
          message: "編集可能なユーザーではありません。",
        })
      );
      return;
    }

    const files = await getFiles(modifyData, "files");
    let modifyDataNoFile = await getWithoutFile(modifyData, "files");

    modifyDataNoFile =
      role !== "Admin"
        ? Object.assign(modifyDataNoFile, {
            apartment_id: userData.apartment_id,
            user_id: userData.id,
          })
        : modifyDataNoFile;

    response = await server.baseSaveWithFile({
      url: "/inquiries/save",
      data: editData.id
        ? { ...editData, ...modifyDataNoFile }
        : modifyDataNoFile,
      files,
    });

    if (response.data) {
      let errMessage = "";
      newAlertData.success = response.data.success;
      if (response.data.type && response.data.type === "validation") {
        const errors = response.data.errors;
        if (errors && errors.length > 0) {
          errors.forEach((element) => {
            errMessage += element.message + `, <br />`;
          });
        }
        newAlertData.message = errMessage;
      } else {
        newAlertData.message = response.data.message;
      }

      response.data.success && callback && callback();
    } else {
      newAlertData.success = false;
      newAlertData.message = "エラー";
    }

    dispatch(setAlert(newAlertData));
  };

  return [saveInquiries];
};

export default useInquiriesSave;
