import { useDispatch } from "react-redux";
import { setConstructionCount } from "reducers/actions";

import server from "helper/server";

const useConstructionCount = () => {
  const dispatch = useDispatch();

  const getConstructionCount = async () => {
    let count = 0;

    const response = await server.call({ url: "/constructions/menu-count" });

    if (response.data && response.data.success) {
      count = response.data.count;
    }

    dispatch(setConstructionCount(count));
  };

  return getConstructionCount;
};

export default useConstructionCount;
