// Show dialog
export const SHOW_CONSTRUCTION_DETAILS_DIALOG =
  "SHOW_CONSTRUCTION_DETAILS_DIALOG";

// CRUD
export const CONSTRUCTION_DETAILS_MODIFY_DATA =
  "CONSTRUCTION_DETAILS_MODIFY_DATA";

export const CONSTRUCTION_DETAILS_EDIT_DATA = "CONSTRUCTION_DETAILS_EDIT_DATA";

export const CONSTRUCTION_DETAILS_LIST_DATA = "CONSTRUCTION_DETAILS_LIST_DATA";

export const CONSTRUCTION_DETAILS_FILTER = "CONSTRUCTION_DETAILS_FILTER";
