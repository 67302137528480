import { useEffect } from "react";
import { Provider } from "react-redux";

import { BrowserRouter } from "react-router-dom";

import { AuthProvider, useAuth } from "context/auth";

import Content from "Content";
import NotAuthenticatedContent from "NotAuthenticatedContent";

import { BaseAlertNew, BaseLoading } from "baseComponents";
import { ImageViewer, PdfViewer } from "additionalComponents";

import Store from "./services/Store";

import "./App.css";

const AppContent = () => {
  const { userData, mainTitle, loading } = useAuth();

  useEffect(() => {
    document.title = mainTitle;
  }, [mainTitle]);

  if (loading) {
    return <BaseLoading />;
  }

  if (userData) return <Content loading={loading} />;

  return <NotAuthenticatedContent />;
};

export default function App() {
  return (
    <AuthProvider>
      <Provider store={Store}>
        <BrowserRouter>
          <AppContent />
        </BrowserRouter>
        {/* <BaseAlert /> */}
        <BaseAlertNew />
        {/* <EmbedPdfViewer /> */}
        {/* <PdfViewerNew /> */}
        <ImageViewer />
        <PdfViewer />
      </Provider>
    </AuthProvider>
  );
}
