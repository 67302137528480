import React from "react";
import { useSelector } from "react-redux";

import { BaseInput, BaseInputPassword } from "baseComponents";

const Form = (props) => {
  const { changeModifyObject } = props;

  const changePasswordData = useSelector(
    (state) => state.users.changePasswordData
  );

  const changeValue = (name, value) => {
    changeModifyObject && changeModifyObject({ [name]: value });
  };

  return (
    <form>
      <div className="grid min-lg:grid-cols-1 gap-1">
        <BaseInput
          label={"ユーザー名"}
          name={"username"}
          value={changePasswordData.username || null}
          disabled={true}
          placeholder={"ユーザー名"}
        />
        <BaseInput
          label={"氏名(姓)"}
          name={"firstname"}
          value={changePasswordData.firstname || null}
          disabled={true}
          placeholder={"氏名(姓)"}
        />
        <BaseInput
          label={"氏名(名)"}
          name={"lastname"}
          value={changePasswordData.lastname || null}
          disabled={true}
          placeholder={"氏名(名)"}
        />
        <div className="mt-10">パスワード</div>
        <hr />
        {!changePasswordData.isAdmin && (
          <BaseInputPassword
            label={"古いパスワード"}
            name={"oldPassword"}
            placeholder={"古いパスワード"}
            changeValue={changeValue}
          />
        )}
        <BaseInputPassword
          label={"パスワード"}
          name={"password"}
          placeholder={"パスワード"}
          changeValue={changeValue}
        />
        <BaseInputPassword
          label={"パスワード確認"}
          name={"password_confirm"}
          placeholder={"パスワード確認"}
          changeValue={changeValue}
        />
      </div>
    </form>
  );
};

export default Form;
