import {
  SHOW_CONSTRUCTION_ITEMS_DIALOG,
  CONSTRUCTION_ITEMS_MODIFY_DATA,
  CONSTRUCTION_ITEMS_EDIT_DATA,
  CONSTRUCTION_ITEMS_LIST_DATA,
  CONSTRUCTION_ITEMS_DETAIL_LIST_DATA,
  ALL_TOTAL_PRICE,
  ALL_ROOM_TOTAL_PRICE,
} from "reducers/actions/constructionItemsTypes";

// ConstructionItems Dialogs
export const setConstructionItemsDialog = (isActive) => {
  return { type: SHOW_CONSTRUCTION_ITEMS_DIALOG, payload: isActive };
};

// ConstructionItems crud
export const setConstructionItemsModifyData = (data) => {
  return { type: CONSTRUCTION_ITEMS_MODIFY_DATA, payload: data };
};

export const setConstructionItemsEditData = (data) => {
  return { type: CONSTRUCTION_ITEMS_EDIT_DATA, payload: data };
};

export const setConstructionItemsList = (data) => {
  return { type: CONSTRUCTION_ITEMS_LIST_DATA, payload: data };
};

export const setConstructionItemsDetailList = (data) => {
  return { type: CONSTRUCTION_ITEMS_DETAIL_LIST_DATA, payload: data };
};

// other

export const setAllTotalPrice = (price) => {
  return { type: ALL_TOTAL_PRICE, payload: price };
};

export const setAllRoomTotalPrice = (price) => {
  return { type: ALL_ROOM_TOTAL_PRICE, payload: price };
};
