import { BaseCard } from "baseComponents";
import { Apartments } from "customComponents";

const ApartmentsPage = () => {
  return (
    <BaseCard title={"マンション"}>
      <Apartments />
    </BaseCard>
  );
};

export default ApartmentsPage;
