import { useEffect, useState } from "react";

import classNames from "classnames";

const Quantity = (props) => {
  const { changeValue, invalid, className, value, disabled, ...rest } = props;

  const [currentValue, setCurrentValue] = useState(value ? value : 0);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className="mb-3">
      <input
        className={classNames(
          "form-control",
          "border",
          "border-solid",
          "border-gray-400",
          "text-sm",
          "font-light",
          "p-0.5",
          "rounded-none",
          { "border-dashed": disabled },
          { "is-invalid": invalid },
          className
        )}
        onChange={(event) => {
          let value = event.target.value;
          if (props.type === "number" && value < 0) value = 0;
          setCurrentValue(value.replace(/^0+/, ""));
          changeValue && changeValue(props.name, value);
        }}
        value={currentValue}
        disabled={disabled}
        {...rest}
      />
    </div>
  );
};

export default Quantity;
