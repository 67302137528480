// Show dialog
export const SHOW_CONSTRUCTION_ITEMS_DIALOG = "SHOW_CONSTRUCTION_ITEMS_DIALOG";

// CRUD
export const CONSTRUCTION_ITEMS_MODIFY_DATA = "CONSTRUCTION_ITEMS_MODIFY_DATA";

export const CONSTRUCTION_ITEMS_EDIT_DATA = "CONSTRUCTION_ITEMS_EDIT_DATA";

export const CONSTRUCTION_ITEMS_LIST_DATA = "CONSTRUCTION_ITEMS_LIST_DATA";

export const CONSTRUCTION_ITEMS_DETAIL_LIST_DATA =
  "CONSTRUCTION_ITEMS_DETAIL_LIST_DATA";

// other
export const ALL_TOTAL_PRICE = "ALL_TOTAL_PRICE";

export const ALL_ROOM_TOTAL_PRICE = "ALL_ROOM_TOTAL_PRICE";
