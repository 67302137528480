import {
  SHOW_USERS_DIALOG,
  SHOW_CHANGE_PASSWORD_DIALOG,
  USERS_MODIFY_DATA,
  USERS_EDIT_DATA,
  USERS_LIST_DATA,
  USERS_CHANGE_PASSWORD_DATA,
} from "reducers/actions/usersTypes";

const initialState = {
  usersDialog: false,
  showChangePasswordDialog: false,
  usersModifyData: {},
  usersEditData: {},
  usersListData: { rows: [], count: 0 },
  changePasswordData: {},
};

const Users = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_USERS_DIALOG:
      return { ...state, usersDialog: action.payload };

    case SHOW_CHANGE_PASSWORD_DIALOG:
      return { ...state, showChangePasswordDialog: action.payload };

    case USERS_MODIFY_DATA:
      return { ...state, usersModifyData: action.payload };

    case USERS_EDIT_DATA:
      return { ...state, usersEditData: action.payload };

    case USERS_LIST_DATA:
      return { ...state, usersListData: action.payload };

    case USERS_CHANGE_PASSWORD_DATA:
      return { ...state, changePasswordData: action.payload };

    default:
      return state;
  }
};

export default Users;
