import {
  SHOW_CONSTRUCTION_DETAILS_DIALOG,
  CONSTRUCTION_DETAILS_MODIFY_DATA,
  CONSTRUCTION_DETAILS_EDIT_DATA,
  CONSTRUCTION_DETAILS_LIST_DATA,
  CONSTRUCTION_DETAILS_FILTER,
} from "reducers/actions/constructionDetailsTypes";

const initialState = {
  constructionDetailsDialog: false,
  constructionDetailsModifyData: {},
  constructionDetailsEditData: {},
  constructionDetailsListData: { rows: [], count: 0 },
  constructionDetailsFilter: null,
};

const ConstructionDetails = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CONSTRUCTION_DETAILS_DIALOG:
      return { ...state, constructionDetailsDialog: action.payload };

    case CONSTRUCTION_DETAILS_MODIFY_DATA:
      return { ...state, constructionDetailsModifyData: action.payload };

    case CONSTRUCTION_DETAILS_EDIT_DATA:
      return { ...state, constructionDetailsEditData: action.payload };

    case CONSTRUCTION_DETAILS_LIST_DATA:
      return { ...state, constructionDetailsListData: action.payload };

    case CONSTRUCTION_DETAILS_FILTER:
      return { ...state, constructionDetailsFilter: action.payload };

    default:
      return state;
  }
};

export default ConstructionDetails;
