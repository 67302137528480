// import { useEffect, useState } from "react";
// import Tooltip from "components/Tooltip";

import defaultImg from "assets/logo-default.png";

import helper from "helper/helper";

const Image = (props) => {
  const { readOnly, value, link, remove } = props;

  return (
    <>
      <div className="inline-block relative mt-5 shadow-gray-500">
        {value ? (
          <div>
            <div className={"thumbnail"}>
              <div className={"image-panel border rounded-lg overflow-hidden"}>
                <img
                  src={
                    link && helper.urlExists(link)
                      ? link
                      : value.fileSrc
                      ? value.fileSrc
                      : defaultImg
                  }
                  className="w-full h-full object-contain"
                  alt={value.fileInfo.file_name}
                />
              </div>
            </div>
            {!readOnly && (helper.urlExists(link) || value.fileSrc) && (
              <div className="absolute -top-3 right-5 z-99 cursor-pointer">
                <span
                  className="la la-times-circle bg-white shadow-md shadow-gray-600 text-2xl text-gray-500 rounded-full hover:bg-gray-500 hover:text-white z-99"
                  onClick={() => remove && remove()}
                />
              </div>
            )}
          </div>
        ) : (
          <div className={"thumbnail"}>
            <div className={"image-panel border rounded-lg overflow-hidden"}>
              <img
                src={defaultImg}
                className="w-full h-full object-cover"
                alt={"Default Image"}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Image;
