import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Tippy from "@tippyjs/react";

import Label from "components/form/Label";

import server from "helper/server";
import helper from "helper/helper";

const BaseSearchSelect = (props) => {
  const {
    invalid,
    className,
    label,
    name,
    value = null,
    disabled,
    placeholder,
    objectName,
    changeValue,
    idField = "id",
    textField = "text",
    customFilter,
    additionalFilter,
  } = props;

  const inputRef = useRef();

  const [data, setData] = useState([]);
  const [currentValue, setCurrentValue] = useState(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    setCurrentValue(null);
    getData();
    customSetValue(value);
  }, [value]);

  useEffect(() => {
    additionalFilter && setCurrentValue(null);

    getData();
  }, [JSON.stringify(additionalFilter)]);

  const customSetValue = async (id) => {
    if (id) {
      const response = await server.baseShow({ objectName, id });
      const responseData = response.data ? response.data : null;
      if (responseData && responseData.success && responseData.data) {
        setCurrentValue({
          id: responseData.data[idField],
          text: responseData.data[textField],
        });
      }
    }
  };

  const setCustomFilter = ({ filterText }) => {
    let filter = [];
    if (filterText) {
      filter.push([textField, "contains", filterText]);
      filter.push("and");
    }

    if (customFilter) {
      filter.push(customFilter);
      filter.push("and");
    }

    if (additionalFilter) {
      filter.push(additionalFilter);
      filter.push("and");
    }

    filter.pop();
    return filter;
  };

  const getData = async (filterText = null) => {
    if (objectName) {
      setLoading(true);

      let filter = setCustomFilter({ filterText });
      let rootFilter = ["status", "=", "active"];
      let rootSort = null;
      let skip = 0;
      let take = 100;

      let select = `["id", ${textField}]`;
      let params = helper.getRequest(
        { skip, take, filter },
        rootFilter,
        rootSort
      );
      params += `select=${select}&`;
      params = params.slice(0, -1);

      const response = await server.baseList({ objectName, params });

      if (response.data && response.data.success && response.data.data) {
        setData(response.data.data.rows);
      }

      setLoading(false);
    }
  };

  const setChangeValue = (id, text) => {
    setVisible(false);
    setCurrentValue({ id, text });
    inputRef.current.value = "";
    changeValue && changeValue(name, id);
  };

  const removeValue = () => {
    setVisible(true);
    setCurrentValue(null);
    changeValue && changeValue(name, null);
  };

  return (
    <div className="mb-3">
      {label && <Label htmlFor="input">{label}</Label>}
      <div className="search-select">
        <Tippy
          ref={{}}
          content={
            loading ? (
              <div className="w-full flex justify-center items-center py-3 px-4">
                <span
                  className={
                    "las la-spinner animate-spin text-2xl text-gray-600 dark:text-gray-300"
                  }
                />
              </div>
            ) : (
              <div className="search-select-menu">
                {data ? (
                  data.map((item, key) => (
                    <div
                      key={"item-" + key}
                      className="item"
                      onClick={() =>
                        setChangeValue(item[idField], item[textField])
                      }
                    >
                      {item[textField]}
                    </div>
                  ))
                ) : (
                  <p>見つかりません</p>
                )}
              </div>
            )
          }
          theme="light-border"
          offset={[0, 8]}
          maxWidth="none"
          className="ml-0 mr-2 -mt-2 -mb-2"
          arrow={false}
          placement="bottom-start"
          // trigger="click"
          interactive
          allowHTML
          animation="shift-toward-extreme"
          visible={visible}
          onClickOutside={() => setVisible(false)}
        >
          <label className="form-control-addon-within relative flex-row-reverse">
            <input
              ref={inputRef}
              name={name}
              autoComplete="off"
              className={classNames(
                "form-control",
                "text-base",
                "font-light",
                { "is-invalid": invalid },
                className
              )}
              placeholder={currentValue ? null : placeholder}
              disabled={!!disabled ? true : !!currentValue}
              // readOnly={true}
              onMouseUpCapture={() => {
                setVisible(true);
                getData();
              }}
              onChange={(e) => {
                setTimeout(() => getData(e.target.value), 500);
              }}
              onBlur={() => (inputRef.current.value = "")}
            />
            {currentValue && (
              <div className="absolute top-0 right-0 bottom-0 left-0">
                <span className="h-full flex justify-start items-center pl-4 text-base font-light">
                  {currentValue.text}
                  {!disabled && (
                    <button
                      type="button"
                      className="absolute right-0 ml-1 la la-times text-xl mr-2"
                      onClick={() => removeValue()}
                    />
                  )}
                </span>
              </div>
            )}
          </label>
        </Tippy>
      </div>
    </div>
  );
};

export default BaseSearchSelect;
