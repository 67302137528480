import React from "react";
import { useSelector } from "react-redux";

import {
  BaseSearchSelect,
  BaseInput,
  BaseInputMask,
  BaseTextarea,
} from "baseComponents";
import FileUpload from "customComponents/Inquiries/FileUpload";

import { useAuth } from "context/auth";

const Form = (props) => {
  const { userData } = useAuth();
  const role = userData ? userData.role : null;

  const { changeModifyObject } = props;

  const inquiriesEditData = useSelector(
    (state) => state.inquiries.inquiriesEditData
  );

  const changeValue = (name, value) => {
    changeModifyObject && changeModifyObject({ [name]: value });
  };

  return (
    <>
      <div className="grid grid-cols-1">
        <BaseSearchSelect
          label={"マンション"}
          name={"apartment_id"}
          value={
            role !== "Admin"
              ? userData.apartment_id || null
              : inquiriesEditData.apartment_id || null
          }
          disabled={role !== "Admin" ? true : false}
          placeholder={"マンション"}
          objectName={"apartments"}
          textField={"apartment_name"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseInput
          label={"お名前"}
          name={"customername"}
          value={inquiriesEditData.customername || null}
          placeholder={"お名前"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseInput
          label={"お部屋番号"}
          name={"room_number"}
          value={inquiriesEditData.room_number || null}
          placeholder={"お部屋番号"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseInputMask
          label={"連絡先"}
          name={"mobile"}
          value={inquiriesEditData.mobile || null}
          placeholder={"連絡先"}
          changeValue={changeValue}
          mask={"99999999999"}
          maskChar={" "}
          permanents={[]}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseInput
          label={"メールアドレス"}
          name={"email"}
          value={inquiriesEditData.email || null}
          placeholder={"メールアドレス"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseInput
          label={"題名"}
          name={"title"}
          value={inquiriesEditData.title || null}
          placeholder={"題名"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseTextarea
          label={"説明"}
          name={"comment"}
          value={inquiriesEditData.comment || null}
          placeholder={"説明"}
          changeValue={changeValue}
          className="h-32"
        />
      </div>

      <div className="grid grid-cols-1 mb-8">
        <FileUpload
          id={inquiriesEditData.id || null}
          multiple={true}
          name="files"
          changeValue={changeValue}
        />
      </div>
    </>
  );
};

export default Form;
