import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// import LayoutAuth from "layouts/Auth";
import LayoutAuth2 from "layouts/Auth2";
// import LayoutAuth3 from "layouts/Auth3";

export default function NotAuthenticatedContent() {
  return (
    <Routes>
      <Route path="auth/*" element={<LayoutAuth2 />} />
      <Route path="/*" element={<Navigate to="/auth/login" replace />} />
    </Routes>
  );
}
