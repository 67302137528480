import { useSelector } from "react-redux";
import classNames from "classnames";

import { BaseInput, BaseLoadButton } from "baseComponents";

const Form = (props) => {
  const { changeModifyObject, check, cancel, confirm, type } = props;

  const constructionsEditData = useSelector(
    (state) => state.constructions.constructionsEditData
  );
  const constructionsModifyData = useSelector(
    (state) => state.constructions.constructionsModifyData
  );

  const changeValue = (name, value) => {
    let tempValue = value;
    changeModifyObject && changeModifyObject(name, tempValue);
  };

  return (
    <>
      <div className="h-24"></div>
      <div
        className={classNames("flex flex-col min-lg:mx-40 p-8 border", {
          "border-0":
            constructionsEditData.finish_status === "confirmed" &&
            type === "confirm",
        })}
      >
        {(type === "create" || type === "check") && (
          <>
            {type === "create" && (
              <div className="mb-2">
                <p className="w-full text-center">
                  上記内容と金額を確認しましたので、工事を依頼します
                </p>
              </div>
            )}
            <div className="grid min-md:grid-cols-2 gap-3">
              <BaseInput
                name={"room_number"}
                value={
                  constructionsEditData.id
                    ? constructionsEditData.room_number
                    : constructionsModifyData.room_number || null
                  //  (role === "Customer" && userData.room_number + " 号室") ||
                }
                placeholder={"お部屋番号"}
                type="number"
                disabled={type === "create" ? false : true}
                changeValue={changeValue}
              />
              <BaseInput
                name={"username"}
                value={
                  constructionsEditData.id
                    ? constructionsEditData.username
                    : constructionsModifyData.username || ""
                  // (role === "Customer" && userData.username) ||
                }
                placeholder={"お名前"}
                disabled={type === "create" ? false : true}
                changeValue={changeValue}
              />
            </div>
          </>
        )}

        {type === "create" && (
          <div className="grid grid-cols-1 mt-8">
            <div className="flex flex-row justify-center items-center">
              <BaseLoadButton
                color="info"
                outlined={true}
                onClick={() => check && check()}
              >
                {/* <span className="text-xl">工事を依頼する</span> */}
                <span className="text-xl">内容確認</span>
              </BaseLoadButton>
            </div>
          </div>
        )}

        {type === "check" && (
          <div className="grid grid-cols-1 mt-8">
            <div className="mb-2">
              <p className="w-full text-center">
                こちらの内容で工事を依頼しますが、よろしいでしょうか
              </p>
            </div>
            <div className="flex flex-row justify-center items-center">
              <BaseLoadButton
                outlined={true}
                color="success"
                icon="check-circle"
                className="mx-12"
                onClick={() => confirm && confirm()}
              >
                <span className="text-xl">
                  {/* // 依頼内容を確認  */}
                  {/* はい、上記内容にて工事を依頼します */}
                  依頼の確認
                </span>
              </BaseLoadButton>
            </div>
            <div className="my-2">
              <p className="w-full text-center">
                キャンセルもしくは、内容を変更する場合は
                <br />
                こちらを押してください
              </p>
            </div>
            <div className="flex flex-row justify-center items-center">
              <BaseLoadButton
                color="danger"
                icon="times"
                outlined={true}
                onClick={() => cancel && cancel()}
              >
                <span className="text-xl">キャンセル</span>
              </BaseLoadButton>
            </div>
          </div>
        )}

        {/* {id &&
          constructionsEditData.finish_status !== "confirmed" &&
          type === "confirm" && (
            <div className="grid grid-cols-1">
              <div className="mb-2">
                <p className="w-full text-center">
                  ・こちらの内容でよろしければ、
                  <br />
                  このページをスクリーンショットもしくは、印刷して保存してください
                  <br />
                  上記内容と金額を確認しましたので、
                </p>
              </div>
              <div className="flex flex-row justify-center items-center">
                <BaseLoadButton
                  color="success"
                  icon="check-circle"
                  outlined={true}
                  onClick={() => confirm && confirm()}
                >
                  // Confirm to request
                  <span className="text-xl">工事を依頼する</span>
                </BaseLoadButton>
              </div>
            </div>
          )} */}
      </div>
    </>
  );
};

export default Form;
