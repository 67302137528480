import React from "react";
import { useSelector } from "react-redux";

import { BaseInput, BaseTextarea } from "baseComponents";

const ReplyForm = (props) => {
  const { changeModifyObject } = props;

  return <></>;
};

export default ReplyForm;
