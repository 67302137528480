import PropTypes from "prop-types";

const Thumb = (props) => {
  const { item } = props;

  return (
    <div className="flex flex-col max-md:flex-row justify-center items-center ml-5 my-5 min-md:border-r min-md:last:border-r-0 max-md:border-b max-md:last:border-b-0 pr-4">
      {item ? (
        <div className="max-md:grid max-md:grid-cols-3 max-md:items-center">
          <div className="flex flex-col justify-center items-center min-md:mt-4 max-md:mt-0 [&>span]:text-xl">
            <h5 className="text-center font-normal mb-2">{item.dayNumber}</h5>
            <h5 className="text-center min-md:mb-4">{item.weekName}</h5>
          </div>
          <div className="flex flex-col justify-center items-center min-md:mt-4 max-md:mt-0 [&>span]:text-xl">
            <img
              alt="condition"
              src={`https://openweathermap.org/img/wn/` + item.icon + `@2x.png`}
            />
          </div>
          <div className="flex flex-col justify-center items-center min-md:mt-4 max-md:mt-0 [&>span]:text-xl">
            <span>{item.minTemp.toFixed(0)}°C</span>
            <span>{item.maxTemp.toFixed(0)}°C</span>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

Thumb.propTypes = {
  item: PropTypes.object,
};

export default Thumb;
