import { useNavigate } from "react-router-dom";

import Button from "components/Button";

const BackToHome = (props) => {
  const navigate = useNavigate();
  const { onClick } = props;
  return (
    <div className="inline-block float-left items-center justify-center mb-4">
      <div className="flex flex-row items-center">
        <Button
          onClick={() => {
            if (onClick) {
              onClick();
            } else {
              navigate("/admin/home");
            }
          }}
          className="py-2 px-4 rounded-md bg-white shadow-sm border-0 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-800"
          outlined={true}
          color={"info"}
        >
          <span className="la la-home mr-2 text-xl dark:text-gray-500" />
          <span className="text-base dark:text-gray-500">トップ画面へ戻る</span>
        </Button>
      </div>
    </div>
  );
};

export default BackToHome;
