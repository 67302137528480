import React from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  setChangePasswordDialog,
  setChangePasswordData,
} from "reducers/actions";
import useChangePassword from "reducers/hooks/useChangePassword";

import { BaseDialog } from "baseComponents";
import Form from "customComponents/Users/ChangePassword/Form";

const ChangePassword = () => {
  const dispatch = useDispatch();

  const showChangePasswordDialog = useSelector(
    (state) => state.users.showChangePasswordDialog
  );
  const changePasswordData = useSelector(
    (state) => state.users.changePasswordData
  );

  const [changePassword] = useChangePassword();

  return (
    <BaseDialog
      title={"パスワード変更"}
      isActive={showChangePasswordDialog}
      hide={() => dispatch(setChangePasswordDialog(false))}
      save={() =>
        changePassword(changePasswordData, () =>
          dispatch(setChangePasswordDialog(false))
        )
      }
      width={"min-lg:w-96"}
    >
      <Form
        changeModifyObject={(data) =>
          dispatch(setChangePasswordData({ ...changePasswordData, ...data }))
        }
      />
    </BaseDialog>
  );
};

export default ChangePassword;
