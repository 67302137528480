import { useState } from "react";

import Pagination from "components/Pagination";

const BasePagination = (props) => {
  const { onPageChange, onPageSizeChange, totalCount = 0 } = props;

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  return (
    <Pagination
      totalCount={totalCount}
      currentPage={page}
      size={size}
      onPageChange={(page) => {
        onPageChange && onPageChange(page);
        setPage(page);
      }}
      onPageSizeChange={(size) => {
        onPageSizeChange && onPageSizeChange(size);
        setSize(size);
      }}
    />
  );
};

export default BasePagination;
