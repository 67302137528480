import { BaseCard } from "baseComponents";
import { Constructions } from "customComponents";

const ConstructionsPage = () => {
  return (
    <BaseCard title={"[申し込み]"}>
      <Constructions />
    </BaseCard>
  );
};

export default ConstructionsPage;
