// Show dialog
export const SHOW_DOCUMENTS_DIALOG = "SHOW_DOCUMENTS_DIALOG";

// CRUD
export const DOCUMENTS_LIST_DATA = "DOCUMENTS_LIST_DATA";

export const DOCUMENTS_MODIFY_DATA = "DOCUMENTS_MODIFY_DATA";

export const DOCUMENTS_EDIT_DATA = "DOCUMENTS_EDIT_DATA";

// Others
export const CATEGORY_FILTER = "CATEGORY_FILTER";
