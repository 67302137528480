import { useDispatch } from "react-redux";

import { setAlert } from "reducers/actions";

import server from "helper/server";

import { useAuth } from "context/auth";

const useUsersSave = () => {
  const { userData } = useAuth();

  const dispatch = useDispatch();

  const saveUsers = async (modifyData, editData, callback) => {
    let response = {};
    let newAlertData = { show: true, success: true, message: "" };

    const role = userData ? userData.role : null;
    if (role !== "Admin") {
      dispatch(
        setAlert({
          show: true,
          success: false,
          message: "管理者権限を持つユーザーではありません",
        })
      );
      return;
    }

    // if (Object.keys(modifyData).length === 0) {
    //   dispatch(
    //     setAlert({
    //       show: true,
    //       success: false,
    //       message: "添付データがありません",
    //     })
    //   );
    //   return;
    // }

    if (!editData.id) {
      const password = modifyData.password ? modifyData.password : null;
      const password_confirm = modifyData.password_confirm
        ? modifyData.password_confirm
        : null;

      if (password && password_confirm) {
        if (
          password.replace(/\s+/g, "") !== password_confirm.replace(/\s+/g, "")
        ) {
          newAlertData.success = false;
          newAlertData.message = "パスワードの繰り返しが正しくありません";
          dispatch(setAlert(newAlertData));
          return;
        }
      }
    }

    if (editData.id) {
      response = await server.baseUpdate({
        objectName: "users",
        data: { ...editData, ...modifyData },
        id: editData.id,
      });
    } else {
      response = await server.baseCreate({
        objectName: "users",
        data: { ...modifyData, status: "active" },
      });
    }

    if (response.data) {
      let errMessage = "";
      newAlertData.success = response.data.success;
      if (response.data.type && response.data.type === "validation") {
        const errors = response.data.errors;
        if (errors && errors.length > 0) {
          errors.forEach((element) => {
            errMessage += element.message + `, <br />`;
          });
        }
        newAlertData.message = errMessage;
      } else {
        newAlertData.message = response.data.message;
      }

      response.data.success && callback && callback();
    } else {
      newAlertData.success = false;
      newAlertData.message = "エラー";
    }

    dispatch(setAlert(newAlertData));
  };

  return [saveUsers];
};

export default useUsersSave;
