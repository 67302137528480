import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// redux
import { setAlert } from "reducers/actions";

import Input from "components/form/Input";
import Button from "components/Button";
import InputGroup from "components/form/InputGroup";

import server from "helper/server";

const InformationPage = () => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await server.call({ url: "/site-info/detail" });
    if (response.data && response.data.data && response.data.success) {
      setTitle(response.data.data.title);
    }
  };

  const changeTitle = async () => {
    setLoading(true);
    const response = await server.call({
      url: "/site-info/change-title",
      data: { title },
    });

    if (response.data && response.data.success) {
      dispatch(
        setAlert({ show: true, success: true, message: response.data.message })
      );
    }

    setLoading(false);
  };

  return (
    <div className="grid grid-cols-2 max-sm:grid-cols-1">
      {/* <h1 className="mb-1">情報</h1> */}
      <div className="bg-white dark:bg-gray-900 p-5">
        <div className="text-xl">会社名変更</div>
        <InputGroup className="border rounded">
          <Input
            value={title || null}
            className="input-group-item"
            placeholder="会社名変更"
            onChange={(e) => setTitle(e.target.value)}
          />
          <Button
            disabled={loading}
            className="input-group-item uppercase border-primary-700"
            onClick={() => changeTitle()}
          >
            <span
              className={`las la-${
                loading ? "spinner animate-spin" : "save"
              } mr-2 text-xl`}
            />
          </Button>
        </InputGroup>
      </div>
    </div>
  );
};

export default InformationPage;
