import { useState } from "react";

import classNames from "classnames";

import Label from "components/form/Label";

const BaseSelectBox = (props) => {
  const {
    label,
    disabled,
    data,
    changeValue,
    value,
    invalid,
    className,
    ...rest
  } = props;

  const [currentValue, setCurrentValue] = useState(value ? value : undefined);

  return (
    <div className="mb-3">
      {label && <Label htmlFor="input">{label}</Label>}
      <div className="custom-select">
        <select
          className={classNames(
            "form-control",
            "border-none",
            { "is-invalid": invalid },
            className
          )}
          value={currentValue}
          onChange={(event) => {
            const value = event.target.value;
            setCurrentValue(value);
            changeValue && changeValue(props.name, value);
          }}
          {...rest}
        >
          <option value={null}>--- 選択 ---</option>
          {data.map((e, key) => (
            <option key={key} value={e.id}>
              {e.text}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default BaseSelectBox;
