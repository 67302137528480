import {
  SHOW_CATEGORIES_DIALOG,
  CATEGORIES_MODIFY_DATA,
  CATEGORIES_EDIT_DATA,
  CATEGORIES_LIST_DATA,
  CATEGORY_COUNT,
} from "reducers/actions/categoriesTypes";

const initialState = {
  categoriesDialog: false,
  categoriesModifyData: {},
  categoriesEditData: {},
  categoriesListData: { rows: [], count: 0 },
  categoryCount: [],
};

const Categories = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CATEGORIES_DIALOG:
      return { ...state, categoriesDialog: action.payload };

    case CATEGORIES_MODIFY_DATA:
      return { ...state, categoriesModifyData: action.payload };

    case CATEGORIES_EDIT_DATA:
      return { ...state, categoriesEditData: action.payload };

    case CATEGORIES_LIST_DATA:
      return { ...state, categoriesListData: action.payload };

    case CATEGORY_COUNT:
      return { ...state, categoryCount: action.payload };

    default:
      return state;
  }
};

export default Categories;
