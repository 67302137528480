import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// redux
import { setAlert, setConstructionsRoomGrid } from "reducers/actions";

import { BaseCard, BaseGridLoading } from "baseComponents";
import RoomDetail from "customComponents/Constructions/RoomGrid/DetailCard";

import server from "helper/server";
import helper from "helper/helper";

import { useAuth } from "context/auth";

const RoomGrid = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userData } = useAuth();
  const role = userData ? userData.role : null;

  const [loading, setLoading] = useState(false);

  const constructionsRoomGridData = useSelector(
    (state) => state.constructions.constructionsRoomGridData
  );

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    setLoading(true);
    dispatch(setConstructionsRoomGrid([]));

    let rootFilter = ["status", "=", "active"];
    let rootSort = [{ selector: "id", desc: true }];

    let select = JSON.stringify([
      "*",
      { CreatedUser: ["id", "firstname"] },
      { UpdatedUser: ["id", "firstname"] },
    ]);
    let filter = [];

    if (role === "Creator") {
      filter = [
        ["apartment_id", "=", userData.apartment_id],
        "and",
        ["finish_status", "=", "confirmed"],
      ];

      let params = helper.getRequest({ filter }, rootFilter, rootSort);
      params += `select=${select}&`;
      params = params.slice(0, -1);

      const response = await server.call({
        url: "/constructions/room-list" + params,
        method: "GET",
      });

      if (response.data && response.data.data) {
        if (response.data.success === false) {
          dispatch(setAlert({ show: true, success: false, message: "間違い" }));
        }
        response.data.success &&
          dispatch(setConstructionsRoomGrid(response.data.data));
      }
    }

    setLoading(false);
  };

  return (
    <div className="relative min-h-[140px]">
      {loading && <BaseGridLoading />}
      {constructionsRoomGridData.length > 0 && (
        <BaseCard>
          <div className="grid grid-cols-10 2xl:grid-cols-8 xl:grid-cols-7 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 max-xs:grid-cols-2 gap-2">
            {constructionsRoomGridData.map((e, key) => (
              <RoomDetail
                key={key}
                roomNumber={e.room_number}
                isNew={e.isNew}
                onClick={() =>
                  navigate(
                    "/admin/construction-room-detail/" +
                      e.id +
                      "/" +
                      e.room_number +
                      "/" +
                      e.username
                  )
                }
              />
            ))}
          </div>
        </BaseCard>
      )}
    </div>
  );
};

export default RoomGrid;
