import { BackToHome } from "additionalComponents";
import RoomGrid from "customComponents/Constructions/RoomGrid";

const ConstructionsRoomGridPage = () => {
  return (
    <>
      <BackToHome />
      <RoomGrid />
    </>
  );
};

export default ConstructionsRoomGridPage;
