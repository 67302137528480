import { useState } from "react";

import Button from "components/Button";

const LoadButton = (props) => {
  const { icon, children, onClick, ...rest } = props;

  const [loading, setLoading] = useState(false);

  return (
    <Button
      disabled={loading}
      onClick={async () => {
        setLoading(true);
        onClick && (await onClick());
        setLoading(false);
      }}
      {...rest}
    >
      <span
        className={`las la-${
          loading ? "spinner animate-spin" : icon ? icon : "save"
        } mr-2 text-xl`}
      />
      {children}
    </Button>
  );
};

export default LoadButton;
