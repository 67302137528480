import { useEffect, useState } from "react";

import classNames from "classnames";

import Label from "components/form/Label";

import helper from "helper/helper";

const BaseInput = (props) => {
  const {
    label,
    helperText,
    changeValue,
    children,
    invalid,
    className,
    name,
    value,
    type,
    halfWidth,
    fullWidth,
    ...rest
  } = props;

  const [currentValue, setCurrentValue] = useState(value ? value : "");

  useEffect(() => {
    setCurrentValue(value ? value : "");
  }, [value]);

  return (
    <div className="mb-3">
      {label && <Label htmlFor="input">{label}</Label>}
      <label className="form-control-addon-within">
        <input
          className={classNames(
            "form-control",
            "text-base",
            "font-light",
            { "is-invalid": invalid },
            className
          )}
          onChange={(event) => {
            let value = event.target.value;
            if (type === "number" && value < 0) value = 0;
            if (fullWidth) value = helper.convertToFull(value);
            if (halfWidth) value = helper.convertToHalf(value);
            setCurrentValue(value);
            changeValue && changeValue(name, value);
          }}
          value={currentValue}
          type={type}
          {...rest}
        />
      </label>
      {helperText && <small className="block mt-2">{helperText}</small>}
    </div>
  );
};

export default BaseInput;
