import {
  SHOW_DOCUMENTS_DIALOG,
  DOCUMENTS_LIST_DATA,
  DOCUMENTS_MODIFY_DATA,
  DOCUMENTS_EDIT_DATA,
  CATEGORY_FILTER,
} from "reducers/actions/documentsTypes";

const initialState = {
  documentsDialog: false,
  documentsListData: { rows: [], count: 0 },
  documentsModifyData: {},
  documentsEditData: {},
  categoryFilter: null,
};

const Documents = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DOCUMENTS_DIALOG:
      return { ...state, documentsDialog: action.payload };

    case DOCUMENTS_LIST_DATA:
      return { ...state, documentsListData: action.payload };

    case DOCUMENTS_MODIFY_DATA:
      return { ...state, documentsModifyData: action.payload };

    case DOCUMENTS_EDIT_DATA:
      return { ...state, documentsEditData: action.payload };

    case CATEGORY_FILTER:
      return { ...state, categoryFilter: action.payload };

    default:
      return state;
  }
};

export default Documents;
