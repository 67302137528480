import { useDispatch } from "react-redux";

import { setAlert } from "reducers/actions";

import server from "helper/server";

import { useAuth } from "context/auth";

const useConstructionsSave = () => {
  const { userData } = useAuth();

  const dispatch = useDispatch();

  const saveConstructions = async (modifyData, editData, items, callback) => {
    let newAlertData = { show: true, success: true, message: "" };

    const role = userData ? userData.role : null;
    const userId = userData ? userData.id : null;
    if (role !== "Customer") {
      dispatch(
        setAlert({
          show: true,
          success: false,
          message: "This customer role is not 'Customer'",
        })
      );
      return;
    }

    if (editData.id && userId !== editData.created_by) {
      dispatch(
        setAlert({
          show: true,
          success: false,
          message: "編集可能なユーザーではありません。",
        })
      );
      return;
    }

    if (items.length === 0) {
      dispatch(
        setAlert({
          show: true,
          success: false,
          message: "数量を入力してください。",
        })
      );
      return;
    }

    let isItemQuantity = false;
    items.map((e) => {
      if (e.quantity > 0) {
        isItemQuantity = true;
        return;
      }
    });

    if (!isItemQuantity) {
      dispatch(
        setAlert({
          show: true,
          success: false,
          message: "いずれかの値がゼロより大きくなければなりません。",
        })
      );
      return;
    }

    // create & update
    const response = await server.call({
      url: "/constructions/save",
      data: { data: { ...modifyData, ...editData }, items },
    });

    if (response.data) {
      let errMessage = "";
      newAlertData.success = response.data.success;
      if (response.data.type && response.data.type === "validation") {
        const errors = response.data.errors;
        if (errors && errors.length > 0) {
          errors.forEach((element) => {
            errMessage += element.message + `, <br />`;
          });
        }
        newAlertData.message = errMessage;
      } else {
        newAlertData.message = response.data.message;
      }

      response.data.success && callback && callback(response.data.id);
    } else {
      newAlertData.success = false;
      newAlertData.message = "エラー";
    }

    // dispatch(setAlert(newAlertData));
  };

  return [saveConstructions];
};

export default useConstructionsSave;
