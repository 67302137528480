// Show dialog
export const SHOW_INQUIRIES_DIALOG = "SHOW_INQUIRIES_DIALOG";

// CRUD
export const INQUIRIES_MODIFY_DATA = "INQUIRIES_MODIFY_DATA";

export const INQUIRIES_EDIT_DATA = "INQUIRIES_EDIT_DATA";

export const INQUIRIES_LIST_DATA = "INQUIRIES_LIST_DATA";

// additional
export const INQUIRIES_GRID_DATA = "INQUIRIES_GRID_DATA";

export const INQUIRY_COUNT = "INQUIRY_COUNT";

export const INQUIRY_REPLY_DIALOG = "INQUIRY_REPLY_DIALOG";
