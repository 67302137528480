import React, { useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

// redux

// import Tooltip from "components/Tooltip";
// import Button from "components/Button";
import { BaseGridLoading } from "baseComponents";

import Quantity from "customComponents/Constructions/CreateAndUpdate/ItemsGrid/Quantity";

const ItemsGrid = (props) => {
  const { changeQuantity, type } = props;

  const [isMore, setIsMore] = useState(false);

  const constructionItemsDetailListData = useSelector(
    (state) => state.constructionItems.constructionItemsDetailListData
  );
  const showGridLoading = useSelector((state) => state.custom.showGridLoading);
  const allTotalPrice = useSelector(
    (state) => state.constructionItems.allTotalPrice
  );

  const changeValue = async (detail_id, value) => {
    changeQuantity && changeQuantity(detail_id, value);
  };

  return (
    <div className="w-full min-h-[130px]">
      {showGridLoading && <BaseGridLoading />}
      {/* List */}
      <div className="relative w-full max-sm:overflow-x-scroll">
        <div className="max-md:max-w-[80vw] min-w-fit">
          <div className="custom-table-head">
            <table className="custom-table">
              <thead>
                <tr>
                  <th className="text-center uppercase w-3/12 max-sm:2/12">
                    工事内容
                  </th>
                  <th className="text-center uppercase w-1/12 max-sm:w-2/12">
                    数量
                  </th>
                  <th className="text-center uppercase w-1/12">単位</th>
                  <th className="text-center uppercase w-2/12">単価</th>
                  <th className="text-center uppercase w-2/12">合計</th>
                  <th className="text-center uppercase w-3/12 max-sm:2/12">
                    備考
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="custom-table-body">
            <table className="custom-table">
              <tbody>
                {constructionItemsDetailListData.map((item, key) => {
                  let more = false;
                  let shortDescription = item.description
                    ? item.description.substring(0, 20)
                    : "";

                  if (item.description && item.description.length >= 20) {
                    shortDescription += " ...";
                    more = true;
                  }

                  return (
                    <tr key={key}>
                      <td className="text-center w-3/12 max-sm:4/12">
                        <span>{item.detail_name}</span>
                        {/* <Tooltip
                        content={item.description}
                        placement="bottom"
                        className="w-32"
                        disabled={!item.description}
                      >
                        <span className="ml-2 text-lg la la-ellipsis-h cursor-pointer"></span>
                      </Tooltip> */}
                      </td>
                      <td className="text-center w-1/12 max-sm:w-2/12">
                        <Quantity
                          name={"quantity"}
                          placeholder={"数量"}
                          type="number"
                          value={item.quantity}
                          disabled={type !== "create"}
                          changeValue={(name, value) =>
                            changeValue(item.detail_id, value)
                          }
                        />
                      </td>
                      <td className="text-center w-1/12">{item.unity}</td>
                      <td className="text-right w-2/12">{item.unit_price}円</td>
                      <td className="text-right w-2/12">
                        {item.total_price}円
                      </td>
                      <td className="text-center w-3/12">
                        <span
                          className={classNames({ "cursor-pointer": more })}
                          onClick={() => setIsMore(!isMore)}
                        >
                          {isMore ? item.description : shortDescription}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {constructionItemsDetailListData.length > 0 && (
            <div className="custom-table-footer">
              <table className="custom-table">
                <tbody>
                  <tr>
                    <td colSpan={3} className="py-1 pl-2 w-1/2">
                      <div className="flex flex-row justify-start items-start">
                        <span className="font-bold text-sm">
                          合計金額（税込）
                        </span>
                      </div>
                    </td>
                    <td colSpan={2} className="pr-4 w-1/2">
                      <div className="flex flex-row justify-end items-end">
                        <span className="font-bold text-sm">
                          {allTotalPrice}円
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemsGrid;
