import React from "react";
import { useSelector } from "react-redux";

import { BaseSwitch, BaseInput, BaseTextarea } from "baseComponents";

import LogoUpload from "customComponents/Apartments/LogoUpload";

const Form = (props) => {
  const apartmentsEditData = useSelector(
    (state) => state.apartments.apartmentsEditData
  );

  const { changeModifyObject } = props;

  const changeValue = (name, value) => {
    changeModifyObject && changeModifyObject({ [name]: value });
  };

  return (
    <>
      <div className="grid grid-cols-1 w-full justify-center">
        <LogoUpload
          id={apartmentsEditData.id || null}
          name="logo"
          changeValue={changeValue}
        />
      </div>

      <div className="grid grid-cols-1 my-4">
        <BaseSwitch
          label={"オプション設定"}
          name={"is_ready"}
          checked={apartmentsEditData.is_ready || null}
          changeValue={changeValue}
        />
      </div>

      <div className="grid grid-cols-1">
        <BaseInput
          label={"マンション"}
          name={"apartment_name"}
          value={apartmentsEditData.apartment_name || null}
          placeholder={"マンション"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseInput
          label={"会社名"}
          name={"company_name"}
          value={apartmentsEditData.company_name || null}
          placeholder={"会社名"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseInput
          label={"緯度"}
          name={"latitude"}
          value={apartmentsEditData.latitude || null}
          placeholder={"緯度"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseInput
          label={"経度"}
          name={"longitude"}
          value={apartmentsEditData.longitude || null}
          placeholder={"経度"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseTextarea
          label={"説明"}
          name={"description"}
          value={apartmentsEditData.description || null}
          placeholder={"説明"}
          changeValue={changeValue}
          className="h-32"
        />
      </div>
    </>
  );
};

export default Form;
