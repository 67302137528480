import axios from "axios";

const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5002/api"
    : "https://inovv.link:8884/api";

// : "https://inovv.link:8884/api";
// : "https://sub.inovv.link:8885/api";
// : "http://153.122.198.9:8883/api";

const fileURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5002/uploads"
    : "https://inovv.link:8884/uploads";

// : "https://inovv.link:8884/uploads";
// : "https://sub.inovv.link:8885/uploads";
// : "http://153.122.198.9:8883/uploads";

const server = axios.create({ baseURL });

const getToken = function () {
  return localStorage.getItem("InnoichiWebToken");
};

export default {
  baseURL,
  fileURL,

  call: async function ({ url, data, method, responseType }) {
    try {
      const token = getToken();
      process.env.NODE_ENV === "development" &&
        console.log({ url, data, method, token });
      const response = await server({
        method: method ? method : "POST",
        headers: { authorization: "Bearer " + token },
        url: url,
        data: data,
        responseType: responseType ? responseType : "json",
      });
      process.env.NODE_ENV === "development" && console.log({ url, response });
      return response;
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
      var status = ex.response ? ex.response.status : 0;
      process.env.NODE_ENV === "development" && console.log({ status });
      if (status === 401) {
        document.location.reload();
      }
      return {
        data: { success: false, data: null, message: "" },
        status,
      };
    }
  },

  // CRUD
  baseList: async function ({ objectName, params, customUrl }) {
    try {
      const token = getToken();
      process.env.NODE_ENV === "development" &&
        console.log({ objectName, params });

      const response = await server({
        method: "GET",
        headers: { authorization: "Bearer " + token },
        url: customUrl ? customUrl : "/base/" + objectName + params,
      });
      process.env.NODE_ENV === "development" &&
        console.log({ objectName, customUrl, response });
      return response;
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
      var status = ex.response ? ex.response.status : 0;
      process.env.NODE_ENV === "development" && console.log({ status });
      if (status === 401) {
        // document.location.reload();
      }
      return {
        data: { success: false, data: [], total: 0, message: "" },
        status,
      };
    }
  },

  baseCreate: async function ({ objectName, data, customUrl }) {
    try {
      process.env.NODE_ENV === "development" &&
        console.log({ objectName, data, customUrl });
      const token = getToken();
      const response = await server({
        url: customUrl ? customUrl : "/base/" + objectName,
        headers: { authorization: "Bearer " + token },
        method: "POST",
        data: data,
      });
      process.env.NODE_ENV === "development" &&
        console.log({ objectName, response });
      return response;
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
      var status = ex.response ? ex.response.status : 0;
      if (status === 401) {
        document.location.reload();
      }
      return {
        data: { success: false, data: null, total: 0, message: "" },
        status,
      };
    }
  },

  baseUpdate: async function ({ objectName, data, id, customUrl }) {
    try {
      process.env.NODE_ENV === "development" &&
        console.log({ objectName, data, id, customUrl });
      const token = getToken();
      const response = await server({
        method: "PUT",
        url: customUrl ? customUrl : "/base/" + objectName + "/" + id,
        headers: { authorization: "Bearer " + token },
        data: data,
      });
      process.env.NODE_ENV === "development" &&
        console.log({ objectName, response });
      return response;
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
      var status = ex.response ? ex.response.status : 0;
      if (status === 401) {
        document.location.reload();
      }
      return {
        data: { success: false, data: null, total: 0, message: "" },
        status,
      };
    }
  },

  baseDelete: async function ({ objectName, id, customUrl }) {
    try {
      process.env.NODE_ENV === "development" &&
        console.log({ objectName, id, customUrl });
      const token = getToken();
      const response = await server({
        method: "DELETE",
        url: customUrl ? customUrl : "/base/" + objectName + "/" + id,
        headers: { authorization: "Bearer " + token },
        data: { id },
      });
      process.env.NODE_ENV === "development" &&
        console.log({ objectName, response });
      return response;
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
      var status = ex.response ? ex.response.status : 0;
      if (status === 401) {
        document.location.reload();
      }
      return {
        data: { success: false, data: null, total: 0, message: "" },
        status,
      };
    }
  },

  // detail
  baseShow: async function ({ objectName, id, customUrl }) {
    try {
      process.env.NODE_ENV === "development" && console.log({ objectName, id });
      const token = getToken();
      const response = await server({
        method: "GET",
        headers: { authorization: "Bearer " + token },
        url: customUrl ? customUrl : "/base/" + objectName + "/" + id,
      });
      process.env.NODE_ENV === "development" &&
        console.log({ objectName, response });
      return response;
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
      var status = ex.response ? ex.response.status : 0;
      process.env.NODE_ENV === "development" && console.log({ status });
      if (status === 401) {
        document.location.reload();
      }
      return {
        data: { success: false, data: null, total: 0, message: "" },
        status,
      };
    }
  },

  baseShowAdvance: async function ({ objectName, params, customUrl }) {
    try {
      process.env.NODE_ENV === "development" &&
        console.log({ objectName, params });
      const token = getToken();
      const response = await server({
        method: "GET",
        headers: { authorization: "Bearer " + token },
        url: customUrl
          ? customUrl
          : "/base/" + objectName + "/get-detail" + params,
      });
      process.env.NODE_ENV === "development" &&
        console.log({ objectName, response });
      return response;
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
      var status = ex.response ? ex.response.status : 0;
      process.env.NODE_ENV === "development" && console.log({ status });
      if (status === 401) {
        document.location.reload();
      }
      return {
        data: { success: false, data: null, total: 0, message: "" },
        status,
      };
    }
  },

  // file
  baseUploadFile: async function ({
    value,
    dataInfo,
    showProgress,
    customUrl,
  }) {
    try {
      let formData = new FormData();
      formData.append("dataInfo", JSON.stringify(dataInfo));
      for (var l = 0; l < value.length; l++) {
        if (value[l].file && value[l].fileInfo) {
          formData.append(
            "file" + l,
            value[l].file,
            value[l].fileInfo.original_name
          );
          formData.append(
            "file" + l + "Info",
            JSON.stringify(value[l].fileInfo)
          );
        }
      }
      const token = getToken();
      const response = await server({
        url: customUrl ? customUrl : "/base/file/upload-file",
        method: "POST",
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
        onUploadProgress: (e) => {
          process.env.NODE_ENV === "development" && console.log(e);
        }, //showProgress ? showProgress : undefined,
      });

      return response.data;
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
      var status = ex.response ? ex.response.status : 0;
      process.env.NODE_ENV === "development" && console.log({ status });
      if (status === 401) {
        document.location.reload();
      }
      return {
        data: { success: false, data: null, total: 0, message: "" },
        status,
      };
    }
  },

  baseDownloadFile: async function ({ fileInfo, customUrl }) {
    process.env.NODE_ENV === "development" &&
      console.log({ fileInfo, customUrl });
    const token = getToken();
    var result = false;
    if (fileInfo) {
      await server({
        url: customUrl ? customUrl : "/base/file/download-file",
        method: "POST",
        data: { fileInfo: JSON.stringify(fileInfo) },
        headers: { authorization: "Bearer " + token },
        responseType: "blob",
      })
        .then((response) => {
          process.env.NODE_ENV === "development" && console.log({ response });
          if (fileInfo.original_name.toLowerCase().indexOf(".pdf") > -1) {
            const file = new Blob([response.data], { type: "application/pdf" });
            const s = window.URL.createObjectURL(file);
            result = s;
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileInfo.original_name);
            document.body.appendChild(link);
            link.click();
            result = true;
          }
        })
        .catch((error) => {
          process.env.NODE_ENV === "development" && console.log({ error });
          result = false;
        });
    }

    return result;
  },

  // save with file
  baseSaveWithFile: async function ({ url, data, method, files }) {
    try {
      let formData = new FormData();
      formData.append("data", JSON.stringify(data));
      if (Array.isArray(files)) {
        files.forEach((e, key) => {
          if (e && e.fileInfo) {
            e.file &&
              formData.append("file" + key, e.file, e.fileInfo.original_name);
            formData.append("fileInfo" + key, JSON.stringify(e.fileInfo));
          }
        });
      }

      const token = getToken();
      process.env.NODE_ENV === "development" &&
        console.log({ url, data, method });
      const response = await server({
        method: method ? method : "POST",
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
        url: url,
        data: formData,
        onUploadProgress: (e) => {
          process.env.NODE_ENV === "development" && console.log(e);
        }, //showProgress ? showProgress : undefined,
      });
      process.env.NODE_ENV === "development" && console.log({ url, response });
      return response;
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
      var status = ex.response ? ex.response.status : 0;
      process.env.NODE_ENV === "development" && console.log({ status });
      if (status === 401) {
        document.location.reload();
      }
      return {
        data: { success: false, data: null, message: "" },
        status,
      };
    }
  },
};
