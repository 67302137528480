import Tooltip from "components/Tooltip";

const FooterLink = () => {
  return (
    <Tooltip
      content={
        "どこに頼んで良いかわかなくてお困りの <br />ちょっとしたお直しをメインで取り扱っています <br />私たちは管理会社Inovvの指定協力会社です"
      }
      placement="top"
    >
      <div
        className="basis-1/3 cursor-pointer inline-block max-md:w-full rounded-xl shadow-sm shadow-teal-900 bg-blue-500 text-white px-7 py-3 animate-bounce"
        onClick={() => {
          const url = "https://innoichi.com";
          window.open(url, "_blank", "noreferrer");
        }}
      >
        <h4 className="text-white text-center text-xl p-0 m-0 mb-2">
          {/* イノイチ（仮） */}
          イノイチWEBリペア
        </h4>
        <p className="w-full text-center">
          お部屋内のちょっとしたお直しの見積りはこちらから（無料）
        </p>
      </div>
    </Tooltip>
  );
};

export default FooterLink;
