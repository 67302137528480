import { useParams } from "react-router-dom";

import CustomShow from "customComponents/News/CustomShow";

const NewsShow = () => {
  const { id } = useParams();

  return <CustomShow id={id} />;
};

export default NewsShow;
