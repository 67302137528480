import {
  SHOW_INQUIRIES_DIALOG,
  INQUIRIES_MODIFY_DATA,
  INQUIRIES_EDIT_DATA,
  INQUIRIES_LIST_DATA,
  INQUIRIES_GRID_DATA,
  INQUIRY_COUNT,
  INQUIRY_REPLY_DIALOG,
} from "reducers/actions/inquiriesTypes";

// Inquiries Dialogs
export const setInquiriesDialog = (isActive) => {
  return { type: SHOW_INQUIRIES_DIALOG, payload: isActive };
};

// Inquiries crud
export const setInquiriesModifyData = (data) => {
  return { type: INQUIRIES_MODIFY_DATA, payload: data };
};

export const setInquiriesEditData = (data) => {
  return { type: INQUIRIES_EDIT_DATA, payload: data };
};

export const setInquiriesList = (data) => {
  return { type: INQUIRIES_LIST_DATA, payload: data };
};

export const setInquiriesGrid = (data) => {
  return { type: INQUIRIES_GRID_DATA, payload: data };
};

export const setInquiryCount = (count) => {
  return { type: INQUIRY_COUNT, payload: count };
};

export const setInquiryReplyDialog = (isActive) => {
  return { type: INQUIRY_REPLY_DIALOG, payload: isActive };
};
