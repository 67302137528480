const SubHeaderTitle = (props) => {
  const { children } = props;
  return (
    <div className="py-4">
      <h4 className="inline pb-1 pr-4 border-b-solid border-b border-b-gray-400 dark:border-gray-700 uppercase text-gray-600 dark:text-gray-700 text-base">
        {children}
      </h4>
    </div>
  );
};

export default SubHeaderTitle;
