import {
  SHOW_ALERT,
  SHOW_GRID_LOADING,
  SHOW_CONFIRM_DIALOG,
  SHOW_CONFIRM_DATA,
  SHOW_IMAGE_DIALOG,
  SHOW_PDF_DIALOG,
  IMAGE_SRC,
  PDF_SRC,
} from "reducers/actions/customTypes";

export const setAlert = (data) => {
  return { type: SHOW_ALERT, payload: data };
};

// show grid loading
export const setShowGridLoading = (isShow) => {
  return { type: SHOW_GRID_LOADING, payload: isShow };
};

// show confirm dialog
export const setConfirmDialog = (isShow) => {
  return { type: SHOW_CONFIRM_DIALOG, payload: isShow };
};

export const setConfirmData = (data) => {
  return { type: SHOW_CONFIRM_DATA, payload: data };
};

// pdf & image
export const setShowImageDialog = (isShow) => {
  return { type: SHOW_IMAGE_DIALOG, payload: isShow };
};

export const setShowPdfDialog = (isShow) => {
  return { type: SHOW_PDF_DIALOG, payload: isShow };
};

export const setImageSrc = (image) => {
  return { type: IMAGE_SRC, payload: image };
};

export const setPdfSrc = (pdf) => {
  return { type: PDF_SRC, payload: pdf };
};
