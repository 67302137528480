import { Routes, Route, Navigate } from "react-router-dom";

import Navigation from "partials/Navigation";
// import TapWeekDays from "customComponents/Weather/TapWeekDays";
import Footer from "partials/Footer";
import ConstructionsRoomGrid from "pages/ConstructionsRoomGrid";
import Inquiries from "pages/Inquiries";

import routes from "routes.js";

import { useAuth } from "context/auth";

const LayoutAdmin = () => {
  const { userData } = useAuth();
  const role = userData ? userData.role : null;

  // const userId = userData ? userData.id : null;

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" && prop.role.includes(role)) {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  const addRoutes = () => {};

  return (
    <>
      <Navigation />
      <main className="workspace">
        <Routes>
          {getRoutes(routes)}
          {role === "Creator" && (
            <Route
              path={"/construction-room-grid"}
              element={<ConstructionsRoomGrid />}
            />
          )}
          {role === "Creator" && (
            <Route path={"/Inquiries"} element={<Inquiries />} />
          )}
          <Route path="/*" element={<Navigate to="/admin/home" replace />} />
        </Routes>
        <Footer />
      </main>
    </>
  );
};

export default LayoutAdmin;
