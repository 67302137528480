import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  toggleDarkMode,
  toggleMenu,
  setChangePasswordDialog,
  setChangePasswordData,
  setAlert,
} from "reducers/actions";

import classNames from "classnames";

import Avatar from "components/Avatar";
import Dropdown from "components/Dropdown";
import Switch from "components/form/Switch";
import Tooltip from "components/Tooltip";

import ChangePassword from "customComponents/Users/ChangePassword";

import useDarkMode from "reducers/hooks/useDarkMode";
import useFullscreen from "reducers/hooks/useFullScreen";

import { useAuth } from "context/auth";

import server from "helper/server";
import helper from "helper/helper";

// import defaultImg from "assets/logo-default.png";

const TopBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData, logo, mainTitle, logOut } = useAuth();

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const menuBarVisible = useSelector((state) => state.root.menuBarVisible);

  const [darkMode, setDarkMode] = useDarkMode();
  const [isFullscreen, toggleFullscreen] = useFullscreen();

  const logoLink = logo ? server.fileURL + "/apartments/" + logo : null;

  return (
    <>
      <ChangePassword />
      <header className="top-bar">
        {/* Menu Toggler */}
        {userData && (
          <button
            className="menu-toggler la la-bars"
            onClick={() => dispatch(toggleMenu(!menuBarVisible))}
          />
        )}
        {/* Brand */}
        <div className="flex flex-row justify-start items-center">
          {/* Logo */}
          {logoLink && helper.urlExists(logoLink) && (
            <div>
              <img
                src={logoLink}
                alt={"Logo"}
                className="w-20 h-20 overflow-hidden object-contain max-sm:w-16 max-sm:h-16"
              />
            </div>
          )}
          {/* Title */}
          <span
            className="brand"
            onClick={() => {
              navigate("/admin/home");
            }}
          >
            {mainTitle}
          </span>
        </div>

        {/* Right */}
        <div className="flex items-center ml-auto">
          {/* Dark Mode */}
          <Tooltip content="ダークモードの切り替え">
            <Switch
              outlined
              checked={darkMode}
              onChange={() => setDarkMode()}
            />
          </Tooltip>

          {/* Fullscreen */}
          <Tooltip content="全画面表示">
            <button
              className={classNames(
                "hidden min-lg:inline-block ml-3 px-2 text-2xl leading-none la",
                {
                  "la-compress-arrows-alt": isFullscreen,
                  "la-expand-arrows-alt": !isFullscreen,
                }
              )}
              onClick={toggleFullscreen}
            />
          </Tooltip>

          {/* User Menu */}
          {userData && (
            <div>
              <Dropdown
                arrow={true}
                content={
                  <div className="w-64">
                    <div className="p-3">
                      <h5 className="uppercase">
                        {userData ? userData.firstname : ""}
                      </h5>
                      <p>{userData ? userData.role : ""}</p>
                    </div>
                    <hr />
                    {userData && userData.role === "Admin" && (
                      <div className="p-2">
                        <div
                          className="flex items-center text-gray-700 dark:text-gray-500 hover:text-primary dark:hover:text-primary cursor-pointer"
                          onClick={() => {
                            setDropdownVisible(false);
                            if (userData) {
                              dispatch(setChangePasswordDialog(true));
                              dispatch(
                                setChangePasswordData({
                                  id: userData.id,
                                  isAdmin: false,
                                  username: userData.username,
                                  firstname: userData.firstname,
                                  lastname: userData.lastname,
                                })
                              );
                            } else {
                              dispatch(
                                setAlert({
                                  show: true,
                                  success: false,
                                  message: "ユーザーは見つかりません",
                                })
                              );
                            }
                          }}
                        >
                          <span className="la la-key text-2xl leading-none mr-2" />
                          パスワード変更
                        </div>
                      </div>
                    )}
                    <hr />
                    <div className="p-2">
                      <div
                        className="flex items-center text-gray-700 dark:text-gray-500 hover:text-primary dark:hover:text-primary cursor-pointer"
                        onClick={() => {
                          logOut();
                          dispatch(toggleDarkMode(false));
                        }}
                      >
                        <span className="la la-power-off text-2xl leading-none mr-2" />
                        ログアウト
                      </div>
                    </div>
                  </div>
                }
                visible={dropdownVisible}
                onClickOutside={() => setDropdownVisible(false)}
              >
                <button
                  className="ml-4"
                  onClick={() =>
                    dropdownVisible
                      ? setDropdownVisible(false)
                      : setDropdownVisible(true)
                  }
                >
                  <Avatar>
                    <span className="uppercase">
                      {userData &&
                        userData.firstname &&
                        userData.firstname.charAt(0)}
                    </span>
                  </Avatar>
                </button>
              </Dropdown>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default TopBar;
