import { useNavigate } from "react-router-dom";

import { BaseCard } from "baseComponents";
// import { BackToHome } from "additionalComponents";

import Button from "components/Button";
import CustomDetail from "customComponents/Constructions/CustomDetail";

const ConstructionCustomDetail = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="mb-4">
        <Button
          color="danger"
          className="uppercase px-8 py-1 rounded-none border-2"
          outlined={true}
          onClick={() => navigate("/admin/constructions")}
        >
          <span className="la la-undo text-xl mr-2"></span>
          戻る
        </Button>
      </div>
      <BaseCard>
        <CustomDetail />
      </BaseCard>
    </>
  );
};

export default ConstructionCustomDetail;
