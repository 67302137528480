import { BaseCard } from "baseComponents";
import { ConstructionDetails } from "customComponents";

const ConstructionDetailsPage = () => {
  return (
    <BaseCard title={"工事内容"}>
      <ConstructionDetails />
    </BaseCard>
  );
};

export default ConstructionDetailsPage;
