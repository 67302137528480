import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// redux
import {
  setAlert,
  setShowGridLoading,
  setCategoriesDialog,
  setCategoriesList,
  setCategoriesModifyData,
  setCategoriesEditData,
} from "reducers/actions";

import useCategoriesSave from "reducers/hooks/useCategoriesSave";
import useCategoryDelete from "reducers/hooks/useCategoryDelete";

// components
import Tooltip from "components/Tooltip";
import Button from "components/Button";

import { BaseDialog } from "baseComponents";

import Grid from "./Grid";
import Form from "./Form";

import server from "helper/server";
import helper from "helper/helper";

const Categories = () => {
  const dispatch = useDispatch();

  const categoriesDialog = useSelector(
    (state) => state.categories.categoriesDialog
  );
  const categoriesModifyData = useSelector(
    (state) => state.categories.categoriesModifyData
  );
  const categoriesEditData = useSelector(
    (state) => state.categories.categoriesEditData
  );

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const [saveCategories] = useCategoriesSave();
  const [deleteCategory] = useCategoryDelete();

  useEffect(() => {
    getList();
  }, [page, size]);

  const getList = async () => {
    dispatch(setShowGridLoading(true));

    let rootFilter = ["status", "=", "active"];
    let rootSort = null;
    let skip = (page - 1) * size;
    let take = size;
    let sort = [{ selector: "id", desc: true }];

    let select = JSON.stringify([
      "*",
      { Apartment: ["id", "apartment_name"] },
      { CreatedUser: ["id", "firstname"] },
      { UpdatedUser: ["id", "firstname"] },
    ]);
    let params = helper.getRequest(
      { skip, take, sort, filter: null },
      rootFilter,
      rootSort
    );
    params += `select=${select}&`;
    params = params.slice(0, -1);

    const response = await server.baseList({
      objectName: "categories",
      params,
    });

    if (response.data && response.data.data) {
      if (response.data.success === false) {
        dispatch(setAlert({ show: true, success: false, message: "間違い" }));
      }
      response.data.success && dispatch(setCategoriesList(response.data.data));
    }

    dispatch(setShowGridLoading(false));
  };

  return (
    <>
      <BaseDialog
        title={"カテゴリー"}
        isActive={categoriesDialog}
        hide={() => {
          dispatch(setAlert({ show: false }));
          dispatch(setCategoriesModifyData({}));
          dispatch(setCategoriesEditData({}));
          dispatch(setCategoriesDialog(false));
        }}
        save={() =>
          saveCategories(categoriesModifyData, categoriesEditData, () => {
            dispatch(setCategoriesModifyData({}));
            dispatch(setCategoriesEditData({}));
            dispatch(setCategoriesDialog(false));
            getList();
          })
        }
        width={"w-[360px]"}
      >
        <Form
          changeModifyObject={(data) =>
            dispatch(
              setCategoriesModifyData({ ...categoriesModifyData, ...data })
            )
          }
        />
      </BaseDialog>
      <div className="w-full">
        {/* custom actions */}
        <section className="flex items-start w-full gap-2 mb-3">
          {/* Refresh */}
          <Tooltip content={"リフレッシュ"} placement="bottom">
            <Button icon color="info" onClick={() => getList()}>
              <span className="la la-sync" />
            </Button>
          </Tooltip>
          {/* Add New */}
          <Tooltip content={"新しい"} placement="bottom">
            <Button
              icon
              color="success"
              onClick={() => dispatch(setCategoriesDialog(true))}
            >
              <span className="la la-plus" />
            </Button>
          </Tooltip>
        </section>
        <Grid
          edit={(data) => {
            dispatch(setCategoriesDialog(true));
            dispatch(
              setCategoriesEditData(Object.assign(categoriesEditData, data))
            );
          }}
          destroy={(id) => deleteCategory(id, () => getList())}
          onPageChange={(page) => setPage(page)}
          onPageSizeChange={(size) => setSize(size)}
        />
      </div>
    </>
  );
};

export default Categories;
