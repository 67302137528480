import {
  SHOW_NEWS_DIALOG,
  NEWS_MODIFY_DATA,
  NEWS_EDIT_DATA,
  NEWS_LIST_DATA,
} from "reducers/actions/newsTypes";

const initialState = {
  newsDialog: false,
  newsModifyData: {},
  newsEditData: {},
  newsListData: { rows: [], count: 0 },
};

const News = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NEWS_DIALOG:
      return { ...state, newsDialog: action.payload };

    case NEWS_MODIFY_DATA:
      return { ...state, newsModifyData: action.payload };

    case NEWS_EDIT_DATA:
      return { ...state, newsEditData: action.payload };

    case NEWS_LIST_DATA:
      return { ...state, newsListData: action.payload };

    default:
      return state;
  }
};

export default News;
