import { useDispatch } from "react-redux";
import { setCategoryCount } from "reducers/actions";

import server from "helper/server";

const useCategoryCount = () => {
  const dispatch = useDispatch();

  const getCategoryCount = async () => {
    const response = await server.call({
      url: "/notifications/category-count",
    });

    if (response.data && response.data.success) {
      dispatch(setCategoryCount(response.data.data));
    }
  };

  return getCategoryCount;
};

export default useCategoryCount;
