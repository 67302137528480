import {
  SHOW_CONSTRUCTION_DETAILS_DIALOG,
  CONSTRUCTION_DETAILS_MODIFY_DATA,
  CONSTRUCTION_DETAILS_EDIT_DATA,
  CONSTRUCTION_DETAILS_LIST_DATA,
  CONSTRUCTION_DETAILS_FILTER,
} from "reducers/actions/constructionDetailsTypes";

// ConstructionDetails Dialogs
export const setConstructionDetailsDialog = (isActive) => {
  return { type: SHOW_CONSTRUCTION_DETAILS_DIALOG, payload: isActive };
};

// ConstructionDetails crud
export const setConstructionDetailsModifyData = (data) => {
  return { type: CONSTRUCTION_DETAILS_MODIFY_DATA, payload: data };
};

export const setConstructionDetailsEditData = (data) => {
  return { type: CONSTRUCTION_DETAILS_EDIT_DATA, payload: data };
};

export const setConstructionDetailsList = (data) => {
  return { type: CONSTRUCTION_DETAILS_LIST_DATA, payload: data };
};

export const setConstructionDetailsFilter = (data) => {
  return { type: CONSTRUCTION_DETAILS_FILTER, payload: data };
};
