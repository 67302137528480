import React from "react";
import { useSelector } from "react-redux";

import { BaseSearchSelect, BaseInput, BaseTextarea } from "baseComponents";

import { useAuth } from "context/auth";

const Form = (props) => {
  const { userData } = useAuth();
  const role = userData ? userData.role : null;

  const constructionDetailsEditData = useSelector(
    (state) => state.constructionDetails.constructionDetailsEditData
  );

  const { changeModifyObject, readOnly = false } = props;

  const changeValue = (name, value) => {
    changeModifyObject && changeModifyObject({ [name]: value });
  };

  return (
    <>
      <div className="grid grid-cols-1">
        <BaseSearchSelect
          label={"マンション"}
          name={"apartment_id"}
          value={
            role !== "Admin"
              ? userData.apartment_id || null
              : constructionDetailsEditData.apartment_id || null
          }
          disabled={role !== "Admin" ? true : false}
          placeholder={"マンション"}
          objectName={"apartments"}
          textField={"apartment_name"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseInput
          label={"工事内容"}
          name={"detail_name"}
          value={constructionDetailsEditData.detail_name || null}
          placeholder={"工事内容"}
          changeValue={changeValue}
          disabled={readOnly}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseInput
          label={"単価"}
          name={"detail_price"}
          value={constructionDetailsEditData.detail_price || null}
          placeholder={"単価"}
          // type="number"
          // fullWidth={true}
          halfWidth={true}
          changeValue={changeValue}
          disabled={readOnly}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseInput
          label={"単位"}
          name={"unity"}
          value={constructionDetailsEditData.unity || null}
          placeholder={"単位"}
          changeValue={changeValue}
          disabled={readOnly}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseTextarea
          label={"備考"}
          name={"description"}
          value={constructionDetailsEditData.description || null}
          placeholder={"説明"}
          changeValue={changeValue}
          className="h-32"
          disabled={readOnly}
        />
      </div>
    </>
  );
};

export default Form;
