// Show dialog
export const SHOW_USERS_DIALOG = "SHOW_USERS_DIALOG";

export const SHOW_CHANGE_PASSWORD_DIALOG = "SHOW_CHANGE_PASSWORD_DIALOG";

// CRUD
export const USERS_MODIFY_DATA = "USERS_MODIFY_DATA";

export const USERS_EDIT_DATA = "USERS_EDIT_DATA";

export const USERS_LIST_DATA = "USERS_LIST_DATA";

// change password
export const USERS_CHANGE_PASSWORD_DATA = "USERS_CHANGE_PASSWORD_DATA";
