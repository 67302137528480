import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import Button from "components/Button";
import Input from "components/form/Input";
import Label from "components/form/Label";

import { useAuth } from "context/auth";
import { setAlert } from "reducers/actions";

const Login = () => {
  const dispatch = useDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { login, loginLoading, message } = useAuth();

  useEffect(() => {
    message && dispatch(setAlert({ show: true, success: false, message }));
  }, [message]);

  const onSubmit = async (event) => {
    dispatch(setAlert({ show: false }));
    event.preventDefault();
    const data = new FormData(event.target);
    login({ username: data.get("username"), password: data.get("password") });
  };

  return (
    <>
      <div className="container flex items-center justify-center mt-20 py-10">
        <div className="w-full min-md:w-1/2 min-xl:w-1/3">
          <form onSubmit={onSubmit}>
            <div className="card mt-5 p-5 min-md:p-10">
              <div className="mb-3">
                <Label className="mb-1" htmlFor="username">
                  ユーザー名
                </Label>

                <label className="form-control-addon-within">
                  <Input
                    id="username"
                    name="username"
                    placeholder="ユーザー名"
                    className="dark:text-gray-300"
                  />
                </label>
              </div>
              <div className="mb-3">
                <Label className="mb-1" htmlFor="password">
                  パスワード
                </Label>
                <label className="form-control-addon-within">
                  <Input
                    id="password"
                    name="password"
                    placeholder="パスワード"
                    type={isPasswordVisible ? "text" : "password"}
                    className="border-none focus:border-none"
                  />
                  <span className="flex items-center pr-4">
                    <button
                      type="button"
                      className={classNames(
                        "text-gray-300",
                        "dark:text-gray-700",
                        "text-xl",
                        "leading-none",
                        {
                          "la la-eye": isPasswordVisible,
                          "la la-eye-slash": !isPasswordVisible,
                        }
                      )}
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    />
                  </span>
                </label>
              </div>
              <Button
                disabled={loginLoading}
                className="uppercase"
                type="submit"
              >
                <span
                  className={`las la-${
                    loginLoading ? "spinner animate-spin" : "sign-in-alt"
                  } text-xl mr-2`}
                />
                ログイン
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
