import { useDispatch } from "react-redux";

import { setAlert } from "reducers/actions";

import server from "helper/server";

import { useAuth } from "context/auth";

const useConstructionDetailsSave = () => {
  const { userData } = useAuth();

  const dispatch = useDispatch();

  const saveConstructionDetails = async (modifyData, editData, callback) => {
    let response = {};
    let newAlertData = { show: true, success: true, message: "" };

    const role = userData ? userData.role : null;
    const userId = userData ? userData.id : null;
    if (role === "Customer") {
      dispatch(
        setAlert({
          show: true,
          success: false,
          message: "権限がありません。",
        })
      );
      return;
    }

    if (editData.id && userId !== editData.created_by) {
      dispatch(
        setAlert({
          show: true,
          success: false,
          message: "編集可能なユーザーではありません。",
        })
      );
      return;
    }

    // if (Object.keys(modifyData).length === 0) {
    //   dispatch(
    //     setAlert({
    //       show: true,
    //       success: false,
    //       message: "添付データがありません",
    //     })
    //   );
    //   return;
    // }

    if (editData.id) {
      response = await server.baseUpdate({
        objectName: "constructionDetails",
        data: { ...editData, ...modifyData },
        id: editData.id,
      });
    } else {
      response = await server.baseCreate({
        objectName: "constructionDetails",
        data: { ...modifyData, status: "active" },
      });
    }

    if (response.data) {
      let errMessage = "";
      newAlertData.success = response.data.success;
      if (response.data.type && response.data.type === "validation") {
        const errors = response.data.errors;
        if (errors && errors.length > 0) {
          errors.forEach((element) => {
            errMessage += element.message + `, <br />`;
          });
        }
        newAlertData.message = errMessage;
      } else {
        newAlertData.message = response.data.message;
      }

      response.data.success && callback && callback();
    } else {
      newAlertData.success = false;
      newAlertData.message = "エラー";
    }

    dispatch(setAlert(newAlertData));
  };

  return [saveConstructionDetails];
};

export default useConstructionDetailsSave;
