import MenuBar from "partials/MenuBar";
import TopBar from "partials/TopBar";

const Navigation = () => {
  return (
    <>
      <TopBar />
      <MenuBar />
    </>
  );
};

export default Navigation;
