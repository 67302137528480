import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setConfirmDialog } from "reducers/actions";

import Button from "components/Button";
import Modal, { ModalBody } from "components/Modal";

const BaseConfirm = (props) => {
  const dispatch = useDispatch();

  const { yesLabel, noLabel, cancel, accept, children } = props;

  const showConfirmDialog = useSelector(
    (state) => state.custom.showConfirmDialog
  );

  return (
    <Modal
      active={showConfirmDialog}
      onClose={() => {
        dispatch(setConfirmDialog(false));
      }}
    >
      <ModalBody className="mx-3 py-3">
        <div className="w-64 max-sm:w-full">
          <div className="w-full block text-xl text-center mb-5">
            {children}
          </div>
          <div className="w-full flex justify-center gap-2">
            <Button
              color="danger"
              className="uppercase"
              outlined
              onClick={() => cancel && cancel()}
            >
              {noLabel || "いいえ"}
            </Button>
            <Button
              color="success"
              className="uppercase"
              onClick={() => accept && accept()}
            >
              {yesLabel || "はい"}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BaseConfirm;
