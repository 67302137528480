import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// redux
import {
  setAlert,
  setShowGridLoading,
  setInquiriesDialog,
  setInquiriesList,
  setInquiriesModifyData,
  setInquiriesEditData,
  setInquiryReplyDialog,
} from "reducers/actions";

import useInquiriesSave from "reducers/hooks/useInquiriesSave";
import useInquiryDelete from "reducers/hooks/useInquiryDelete";

// components
import Tooltip from "components/Tooltip";
import Button from "components/Button";

import { BaseDialog } from "baseComponents";

import Grid from "./Grid";
import Form from "./Form";

import ReplyForm from "./ReplyForm";

import server from "helper/server";
import helper from "helper/helper";

const Inquiries = () => {
  const dispatch = useDispatch();

  const inquiriesDialog = useSelector(
    (state) => state.inquiries.inquiriesDialog
  );
  const activeInquiryReplyDialog = useSelector(
    (state) => state.inquiries.activeInquiryReplyDialog
  );
  const inquiriesModifyData = useSelector(
    (state) => state.inquiries.inquiriesModifyData
  );
  const inquiriesEditData = useSelector(
    (state) => state.inquiries.inquiriesEditData
  );

  const [saveInquiries] = useInquiriesSave();
  const [deleteInquiry] = useInquiryDelete();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  useEffect(() => {
    getList();
  }, [page, size]);

  const getList = async () => {
    dispatch(setShowGridLoading(true));

    let rootFilter = ["status", "=", "active"];
    let rootSort = null;
    let skip = (page - 1) * size;
    let take = size;
    let sort = [{ selector: "id", desc: true }];

    let select = JSON.stringify([
      "*",
      { Apartment: ["id", "apartment_name"] },
      { CreatedUser: ["id", "firstname"] },
      { UpdatedUser: ["id", "firstname"] },
    ]);
    let params = helper.getRequest(
      { skip, take, sort, filter: null },
      rootFilter,
      rootSort
    );
    params += `select=${select}&`;
    params = params.slice(0, -1);

    const response = await server.baseList({
      objectName: "inquiries",
      params,
    });

    if (response.data && response.data.data) {
      if (response.data.success === false) {
        dispatch(setAlert({ show: true, success: false, message: "間違い" }));
      }
      response.data.success && dispatch(setInquiriesList(response.data.data));
    }

    dispatch(setShowGridLoading(false));
  };

  return (
    <>
      <BaseDialog
        title={"マンション"}
        isActive={inquiriesDialog}
        hide={() => {
          dispatch(setAlert({ show: false }));
          dispatch(setInquiriesModifyData({}));
          dispatch(setInquiriesEditData({}));
          dispatch(setInquiriesDialog(false));
        }}
        save={() =>
          saveInquiries(inquiriesModifyData, inquiriesEditData, () => {
            dispatch(setInquiriesModifyData({}));
            dispatch(setInquiriesEditData({}));
            dispatch(setInquiriesDialog(false));
            getList();
          })
        }
        width={"min-lg:w-[420px]"}
      >
        <Form
          changeModifyObject={(data) =>
            dispatch(
              setInquiriesModifyData({ ...inquiriesModifyData, ...data })
            )
          }
        />
      </BaseDialog>

      {/* Reply */}
      <BaseDialog
        title={"Reply Inquiry"}
        isActive={activeInquiryReplyDialog}
        hide={() => {
          dispatch(setInquiryReplyDialog(false));
          dispatch(setAlert({ show: false }));
        }}
        saveIcon="paper-plane"
        save={() => {}}
        width={"w-[360px]"}
      >
        <ReplyForm changeModifyObject={(data) => {}} />
      </BaseDialog>
      <div className="w-full">
        {/* custom actions */}
        <section className="flex items-start w-full gap-2 mb-3">
          {/* Refresh */}
          <Tooltip content={"リフレッシュ"} placement="bottom">
            <Button icon color="info" onClick={() => getList()}>
              <span className="la la-sync" />
            </Button>
          </Tooltip>
        </section>
        <Grid
          edit={(data) => {
            dispatch(setInquiriesDialog(true));
            dispatch(
              setInquiriesEditData(Object.assign(inquiriesEditData, data))
            );
          }}
          destroy={(id) => deleteInquiry(id, () => getList())}
          onPageChange={(page) => setPage(page)}
          onPageSizeChange={(size) => setSize(size)}
          reply={() => dispatch(setInquiryReplyDialog(true))}
        />
      </div>
    </>
  );
};

export default Inquiries;
