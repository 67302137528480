import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import LayoutAdmin from "layouts/Admin";

export default function Content() {
  return (
    <Routes>
      <Route path="admin/*" element={<LayoutAdmin />} />
      <Route path="/*" element={<Navigate to="/admin/home" replace />} />
    </Routes>
  );
}
