import { BaseCard } from "baseComponents";
import { Inquiries } from "customComponents";

const InquiriesPage = () => {
  return (
    <BaseCard title={"お問い合わせ"}>
      <Inquiries />
    </BaseCard>
  );
};

export default InquiriesPage;
