// Show dialog
export const SHOW_GREETINGS_DIALOG = "SHOW_GREETINGS_DIALOG";

// CRUD
export const GREETINGS_MODIFY_DATA = "GREETINGS_MODIFY_DATA";

export const GREETINGS_EDIT_DATA = "GREETINGS_EDIT_DATA";

export const GREETINGS_LIST_DATA = "GREETINGS_LIST_DATA";

export const GREETINGS_GRID_DATA = "GREETINGS_GRID_DATA";

export const GREETING_COUNT = "GREETING_COUNT";

// Others
export const USERS_FILTER = "USERS_FILTER";
