import {
  SHOW_CONSTRUCTIONS_DIALOG,
  CONSTRUCTIONS_MODIFY_DATA,
  CONSTRUCTIONS_EDIT_DATA,
  CONSTRUCTIONS_LIST_DATA,
  CONSTRUCTIONS_GRID_DATA,
  CONSTRUCTIONS_ROOM_GRID_DATA,
  CONSTRUCTIONS_ROOM_DETAIL_LIST_DATA,
  CONSTRUCTION_COUNT,
} from "reducers/actions/constructionsTypes";

// Constructions Dialogs
export const setConstructionsDialog = (isActive) => {
  return { type: SHOW_CONSTRUCTIONS_DIALOG, payload: isActive };
};

// Constructions crud
export const setConstructionsModifyData = (data) => {
  return { type: CONSTRUCTIONS_MODIFY_DATA, payload: data };
};

export const setConstructionsEditData = (data) => {
  return { type: CONSTRUCTIONS_EDIT_DATA, payload: data };
};

export const setConstructionsList = (data) => {
  return { type: CONSTRUCTIONS_LIST_DATA, payload: data };
};

export const setConstructionsGrid = (data) => {
  return { type: CONSTRUCTIONS_GRID_DATA, payload: data };
};

export const setConstructionsRoomGrid = (data) => {
  return { type: CONSTRUCTIONS_ROOM_GRID_DATA, payload: data };
};

export const setConstructionsRoomDetailList = (data) => {
  return { type: CONSTRUCTIONS_ROOM_DETAIL_LIST_DATA, payload: data };
};

export const setConstructionCount = (count) => {
  return { type: CONSTRUCTION_COUNT, payload: count };
};
