import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"; // redux
import {
  setAlert,
  setConstructionsModifyData,
  setConstructionsEditData,
  setConstructionItemsDetailList,
  setAllTotalPrice,
  setConfirmData,
  setShowGridLoading,
} from "reducers/actions";

import useConstructionsSave from "reducers/hooks/useConstructionsSave";

import { BackToHome } from "additionalComponents";
import { BaseCard, BaseGridLoading, BaseConfirmData } from "baseComponents";
// import AddItem from "customComponents/Constructions/CreateAndUpdate/AddItem";
import ItemsGrid from "customComponents/Constructions/CreateAndUpdate/ItemsGrid";
import Form from "customComponents/Constructions/CreateAndUpdate/Form";

import { useAuth } from "context/auth";

import server from "helper/server";
import helper from "helper/helper";

const CreateAndUpdate = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id = null, type } = props;

  const { userData } = useAuth();

  const constructionsEditData = useSelector(
    (state) => state.constructions.constructionsEditData
  );
  const constructionsModifyData = useSelector(
    (state) => state.constructions.constructionsModifyData
  );
  const constructionItemsDetailListData = useSelector(
    (state) => state.constructionItems.constructionItemsDetailListData
  );

  const [saveConstructions] = useConstructionsSave();

  const [isReady, setIsReady] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkApartment();
  }, []);

  const checkApartment = async () => {
    setLoading(true);
    const apartment_id = userData.apartment_id;
    if (apartment_id) {
      const response = await server.call({
        url: "/constructions/check-apartment",
        data: { apartment_id },
      });
      if (response.data && response.data.success) {
        setIsReady(response.data.check);
        if (response.data.check) {
          id && getData();
          type !== "check" && getItemsList();
        }
      } else {
        setIsReady(false);
      }
    }

    if (type === "check" && constructionItemsDetailListData.length === 0) {
      navigate("/admin/construction/create");
    }
    setLoading(false);
  };

  const getData = async () => {
    if (type !== "check") {
      dispatch(setConstructionsEditData({}));
      dispatch(setConstructionsModifyData({}));
    }
    const response = await server.baseShow({ objectName: "constructions", id });
    if (response.data.success && response.data.data) {
      dispatch(setConstructionsEditData(response.data.data));
    }
  };

  const getItemsList = async () => {
    dispatch(setShowGridLoading(true));
    if (type !== "check") {
      dispatch(setConstructionItemsDetailList([]));
      dispatch(setAllTotalPrice(0));
      dispatch(setConstructionsModifyData({}));
    }

    let filter = ["status", "<>", "deleted"];
    let params = "";
    if (id) {
      let select = JSON.stringify([
        "*",
        { Construction: ["id", "construction_name"] },
      ]);
      filter = [
        ["construction_id", "=", id],
        "and",
        ["created_by", "=", userData.id],
        "and",
        filter,
      ];
      params = helper.getRequest(
        { skip: null, take: null, sort: null, filter },
        null,
        null
      );
      params += `select=${select}&`;
      params = params.slice(0, -1);
    }

    const response = await server.call({
      url: "/construction-items/detail-list" + params,
      data: { id },
    });
    if (response.data && response.data.success) {
      let totalPrice = 0;
      dispatch(setConstructionItemsDetailList(response.data.data.list));
      response.data.data.list.map(
        (item) =>
          (totalPrice += !isNaN(parseFloat(item.total_price))
            ? parseFloat(item.total_price)
            : 0)
      );
      dispatch(setAllTotalPrice(totalPrice));
    } else {
      navigate("/admin/construction/create");
    }

    dispatch(setShowGridLoading(false));
  };

  const changeQuantity = (detail_id, quantity) => {
    let data = null;
    let tempAllTotalPrice = 0;
    let tempListData = Object.assign([], constructionItemsDetailListData);
    data = tempListData.find((e) => e.detail_id === detail_id);

    if (data && quantity && quantity > 0) {
      data.quantity = parseInt(quantity);
      data.total_price = data.unit_price * quantity;
      tempListData.map(
        (item) =>
          (tempAllTotalPrice += !isNaN(parseFloat(item.total_price))
            ? parseFloat(item.total_price)
            : 0)
      );
    }

    dispatch(setAllTotalPrice(tempAllTotalPrice));
    dispatch(setConstructionItemsDetailList(tempListData));
  };

  // const cancel = async (type) => {
  //   const response = await server.call({
  //     url: "/constructions/cancel",
  //     data: { id, type: type },
  //   });

  //   if (response.data.data && response.data.success) {
  //     dispatch(
  //       setAlert({
  //         show: response.data.success,
  //         message: response.data.message,
  //       })
  //     );
  //   }
  //   dispatch(setConfirmData({ show: false, message: "" }));
  //   navigate("/admin/home");
  // };

  const backAndCancel = async () => {
    if (type === "create" || type === "check") {
      if (constructionItemsDetailListData.length > 0) {
        dispatch(
          setConfirmData({
            show: true,
            cancel: async () => {
              dispatch(setConfirmData({ show: false, message: "" }));
              navigate("/admin/home");
            },
            accept: async () => {
              let valMsg = "";
              if (constructionItemsDetailListData.length === 0) {
                valMsg += "数量を入力してください。";
              }
              if (!constructionsModifyData.username) {
                valMsg += valMsg ? " <br />" : "";
                valMsg += "部屋番号は必要です。";
              }
              if (!constructionsModifyData.username) {
                valMsg += valMsg ? " <br />" : "";
                valMsg += "お名前は必要です。";
              }
              if (valMsg !== "") {
                dispatch(setConfirmData({ show: false, message: "" }));
                dispatch(
                  setAlert({
                    show: true,
                    success: false,
                    message: valMsg,
                  })
                );
                return;
              }

              let isItemQuantity = false;
              constructionItemsDetailListData.forEach((e) => {
                if (e.quantity > 0) {
                  isItemQuantity = true;
                  return;
                }
              });
              if (!isItemQuantity) {
                dispatch(
                  setAlert({
                    show: true,
                    success: false,
                    message: "いずれかの値がゼロより大きくなければなりません。",
                  })
                );
                return;
              } else {
                navigate("/admin/construction/check");
              }
            },
            message: "これまで入力した内容を保存しますか。",
          })
        );
      }
    } else {
      navigate("/admin/home");
    }
  };

  const customConfirm = async () => {
    dispatch(setConfirmData({ show: false, message: "" }));
    dispatch(
      setConfirmData({
        show: true,
        cancel: async () => {
          dispatch(setConfirmData({ show: false, message: "" }));
          navigate("/admin/home");
        },
        accept: async () => {
          dispatch(setShowGridLoading(true));
          saveConstructions(
            constructionsModifyData,
            constructionsEditData,
            constructionItemsDetailListData,
            (id) => {
              dispatch(setConfirmData({ show: false, message: "" }));
              dispatch(setConstructionsModifyData({}));
              dispatch(setConstructionsEditData({}));
              getItemsList();
              if (id) {
                navigate("/admin/construction/finish/" + id);
              } else {
                navigate("/admin/home");
              }
            }
          );
          dispatch(setShowGridLoading(false));
          // const response = await server.call({
          //   url: "/constructions/confirm",
          //   data: { id },
          // });
          // // redux data
          // dispatch(
          //   setAlert({
          //     show: true,
          //     success: response.data.success,
          //     message: response.data.message,
          //   })
          // );
          // dispatch(setConfirmData({ show: false, message: "" }));
          // navigate("/admin/construction/finish/" + id);
        },
        message: "工事を依頼しますか。",
      })
    );
  };

  if (isReady) {
    return (
      <>
        <BaseConfirmData />
        <BackToHome onClick={backAndCancel} />
        <BaseCard>
          <div className="relative">
            <div className="mb-8">
              {/* {type === "confirm" && constructionsEditData.id && (
              <div>
                <span className="font-medium text-base">
                  {constructionsEditData.room_number}号室 -{" "}
                  {constructionsEditData.username}
                </span>
              </div>
            )} */}
              {/* {type === "create" && (
              <AddItem
                onClick={(item) => {
                  let items = constructionItemsDetailListData;
                  items.push(item);
                  let totalPrice = allTotalPrice;
                  totalPrice = totalPrice + item.total_price;
                  dispatch(
                    setConstructionItemsDetailList(Object.assign([], items))
                  );
                  dispatch(setAllTotalPrice(totalPrice));
                }}
              />
            )} */}
              {/* List */}
              <ItemsGrid changeQuantity={changeQuantity} type={type} />
              {/* <div className="flex justify-center items-center py-12">
              <span className="font-medium text-2xl text-red-700">
                ただいま準備中です。
              </span>
            </div> */}
              {/* Create & Update Form */}
              <Form
                id={id}
                type={type}
                changeModifyObject={(name, value) => {
                  dispatch(
                    setConstructionsModifyData({
                      ...constructionsModifyData,
                      [name]: value,
                    })
                  );
                }}
                check={() => {
                  let valMsg = "";
                  if (constructionItemsDetailListData.length === 0) {
                    valMsg += "数量を入力してください。";
                  }
                  if (!constructionsModifyData.username) {
                    valMsg += valMsg ? " <br />" : "";
                    valMsg += "部屋番号は必要です。";
                  }
                  if (!constructionsModifyData.username) {
                    valMsg += valMsg ? " <br />" : "";
                    valMsg += "お名前は必要です。";
                  }
                  if (valMsg !== "") {
                    dispatch(
                      setAlert({ show: true, success: false, message: valMsg })
                    );
                    return;
                  }

                  if (
                    constructionItemsDetailListData.length > 0 &&
                    constructionsModifyData.username &&
                    constructionsModifyData.username
                  ) {
                    let isItemQuantity = false;
                    constructionItemsDetailListData.forEach((e) => {
                      if (e.quantity > 0) {
                        isItemQuantity = true;
                        return;
                      }
                    });
                    if (!isItemQuantity) {
                      dispatch(
                        setAlert({
                          show: true,
                          success: false,
                          message:
                            "いずれかの値がゼロより大きくなければなりません。",
                        })
                      );
                      return;
                    } else {
                      navigate("/admin/construction/check");
                    }
                  }
                }}
                confirm={customConfirm}
                cancel={backAndCancel}
              />
            </div>
          </div>
        </BaseCard>
      </>
    );
  } else {
    return (
      <>
        <BackToHome />
        <BaseCard>
          {loading ? (
            <div className="h-32">
              <BaseGridLoading />
            </div>
          ) : (
            <div className="bg-gray-100 w-full min-h-[500px] flex flex-row justify-center items-center">
              <p className="inline-block text-center text-3xl">
                ただいま、準備中です。
              </p>
            </div>
          )}
        </BaseCard>
      </>
    );
  }
};

export default CreateAndUpdate;
