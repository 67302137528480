import Tippy from "@tippyjs/react";
import classNames from "classnames";

const Tooltip = (props) => {
  const { className, content, children, ...rest } = props;

  return (
    <Tippy
      theme="light-border tooltip"
      touch={["hold", 500]}
      offset={[0, 12]}
      interactive
      animation="scale"
      appendTo={document.body}
      content={
        <div
          className={classNames(className)}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      }
      {...rest}
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
