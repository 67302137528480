import {
  SHOW_GREETINGS_DIALOG,
  GREETINGS_MODIFY_DATA,
  GREETINGS_EDIT_DATA,
  GREETINGS_LIST_DATA,
  GREETINGS_GRID_DATA,
  GREETING_COUNT,
  USERS_FILTER,
} from "reducers/actions/greetingsTypes";

const initialState = {
  greetingsDialog: false,
  greetingsModifyData: {},
  greetingsEditData: {},
  greetingsListData: { rows: [], count: 0 },
  greetingsGridData: { rows: [], count: 0 },
  greetingCount: [],
  usersFilter: null,
};

const Greetings = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_GREETINGS_DIALOG:
      return { ...state, greetingsDialog: action.payload };

    case GREETINGS_MODIFY_DATA:
      return { ...state, greetingsModifyData: action.payload };

    case GREETINGS_EDIT_DATA:
      return { ...state, greetingsEditData: action.payload };

    case GREETINGS_LIST_DATA:
      return { ...state, greetingsListData: action.payload };

    case GREETINGS_GRID_DATA:
      return { ...state, greetingsListData: action.payload };

    case GREETING_COUNT:
      return { ...state, commentCount: action.payload };

    case USERS_FILTER:
      return { ...state, usersFilter: action.payload };

    default:
      return state;
  }
};

export default Greetings;
