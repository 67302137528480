import { BaseCard } from "baseComponents";
import { Categories } from "customComponents";

const CategoriesPage = () => {
  return (
    <BaseCard title={"カテゴリー"}>
      <Categories />
    </BaseCard>
  );
};

export default CategoriesPage;
