module.exports.defaultValue = {
  file: null,
  fileSrc: null,
  fileInfo: {
    id: null,
    link: null,
    field_name: null,
    original_name: null,
    generated_name: null,
    size: null,
    extension: null,
  },
};

module.exports.getFileSrc = async (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

module.exports.base64toBlob = async (data) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr(
    "data:application/pdf;base64,".length
  );

  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
};
