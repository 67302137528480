import {
  SHOW_EMAILS_DIALOG,
  EMAILS_MODIFY_DATA,
  EMAILS_EDIT_DATA,
  EMAILS_LIST_DATA,
} from "reducers/actions/emailsTypes";

const initialState = {
  emailsDialog: false,
  emailsModifyData: {},
  emailsEditData: {},
  emailsListData: { rows: [], count: 0 },
};

const Emails = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_EMAILS_DIALOG:
      return { ...state, emailsDialog: action.payload };

    case EMAILS_MODIFY_DATA:
      return { ...state, emailsModifyData: action.payload };

    case EMAILS_EDIT_DATA:
      return { ...state, emailsEditData: action.payload };

    case EMAILS_LIST_DATA:
      return { ...state, emailsListData: action.payload };

    default:
      return state;
  }
};

export default Emails;
