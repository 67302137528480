// Show dialog
export const SHOW_CONSTRUCTIONS_DIALOG = "SHOW_CONSTRUCTIONS_DIALOG";

// CRUD
export const CONSTRUCTIONS_MODIFY_DATA = "CONSTRUCTIONS_MODIFY_DATA";

export const CONSTRUCTIONS_EDIT_DATA = "CONSTRUCTIONS_EDIT_DATA";

export const CONSTRUCTIONS_LIST_DATA = "CONSTRUCTIONS_LIST_DATA";

// additional
export const CONSTRUCTIONS_GRID_DATA = "CONSTRUCTIONS_GRID_DATA";

export const CONSTRUCTIONS_ROOM_GRID_DATA = "CONSTRUCTIONS_ROOM_GRID_DATA";

export const CONSTRUCTIONS_ROOM_DETAIL_LIST_DATA =
  "CONSTRUCTIONS_ROOM_DETAIL_LIST_DATA";

export const CONSTRUCTION_COUNT = "CONSTRUCTION_COUNT";
