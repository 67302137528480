const config = {
  darkMode: false,
  menuBarVisible: true,
  darkMode: false,
  brandedMenu: false,
  menuType: "default",
  theme: "default",
  gray: "default",
  fonts: "default",
};

export default config;
