import {
  SHOW_CATEGORIES_DIALOG,
  CATEGORIES_MODIFY_DATA,
  CATEGORIES_EDIT_DATA,
  CATEGORIES_LIST_DATA,
  CATEGORY_COUNT,
} from "reducers/actions/categoriesTypes";

// Categories Dialogs
export const setCategoriesDialog = (isActive) => {
  return { type: SHOW_CATEGORIES_DIALOG, payload: isActive };
};

// Categories crud
export const setCategoriesModifyData = (data) => {
  return { type: CATEGORIES_MODIFY_DATA, payload: data };
};

export const setCategoriesEditData = (data) => {
  return { type: CATEGORIES_EDIT_DATA, payload: data };
};

export const setCategoriesList = (data) => {
  return { type: CATEGORIES_LIST_DATA, payload: data };
};

export const setCategoryCount = (count) => {
  return { type: CATEGORY_COUNT, payload: count };
};
