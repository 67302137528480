import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  setConstructionItemsDetailList,
  setAllTotalPrice,
} from "reducers/actions";

import server from "helper/server";
import helper from "helper/helper";

import { useAuth } from "context/auth";

import { BaseGridLoading } from "baseComponents";

const CustomDetail = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { userData } = useAuth();

  const [loading, setLoading] = useState(false);

  const allTotalPrice = useSelector(
    (state) => state.constructionItems.allTotalPrice
  );

  const constructionItemsDetailListData = useSelector(
    (state) => state.constructionItems.constructionItemsDetailListData
  );

  const [roomNumber, setRoomNumber] = useState(null);
  const [username, setUsername] = useState(null);

  useEffect(() => {
    getItemsList();
  }, [id]);

  const getItemsList = async () => {
    setLoading(true);
    dispatch(setConstructionItemsDetailList([]));
    dispatch(setAllTotalPrice(0));

    if (id && userData.role === "Admin") {
      let select = JSON.stringify([
        "*",
        {
          Construction: ["id", "construction_name", "room_number", "username"],
        },
        { Detail: ["id", "detail_name"] },
        { CreatedUser: ["id", "firstname"] },
        { UpdatedUser: ["id", "firstname"] },
      ]);
      let filter = [
        ["construction_id", "=", id],
        "and",
        ["status", "<>", "deleted"],
      ];
      let params = helper.getRequest(
        { skip: null, take: null, sort: null, filter },
        null,
        null
      );
      params += `select=${select}&`;
      params = params.slice(0, -1);

      const response = await server.call({
        url: "/construction-items/detail-list" + params,
        data: { id },
      });
      if (response.data && response.data.data && response.data.success) {
        let totalPrice = 0;
        setRoomNumber(response.data.data.roomNumber);
        setUsername(response.data.data.username);
        dispatch(setConstructionItemsDetailList(response.data.data.list));
        response.data.data.list.map(
          (item) =>
            (totalPrice += !isNaN(parseFloat(item.total_price))
              ? parseFloat(item.total_price)
              : 0)
        );
        dispatch(setAllTotalPrice(totalPrice));
      }
    }

    setLoading(false);
  };

  return (
    <div className="w-full relative">
      {loading && <BaseGridLoading />}
      <div className="min-h-3">
        <span className="font-medium text-xl">
          {roomNumber && roomNumber + "号室"} {username && " - " + username}
        </span>
      </div>
      {/* List */}
      <div className="relative min-h-[200px] overflow-x-scroll w-full max-w-full pb-2">
        <div className="max-md:max-w-[80vw] min-w-fit">
          <div className="custom-table-head">
            <table className="custom-table">
              <thead>
                <tr className="h-8">
                  <th className="text-center uppercase w-6/12">工事内容</th>
                  <th className="text-center uppercase w-1/12">数量</th>
                  <th className="text-center uppercase w-1/12">単位</th>
                  <th className="text-center uppercase w-2/12">単価</th>
                  <th className="text-center uppercase w-2/12">合計</th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="custom-table-body">
            <table className="custom-table">
              <tbody>
                {constructionItemsDetailListData.map((item, key) => (
                  <tr key={key}>
                    <td className="text-center w-6/12">{item.detail_name}</td>
                    <td className="text-center w-1/12">{item.quantity}</td>
                    <td className="text-center w-1/12">{item.unity}</td>
                    <td className="text-right w-2/12">{item.unit_price}円</td>
                    <td className="text-right w-2/12">{item.total_price}円</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {constructionItemsDetailListData.length > 0 && (
            <div className="custom-table-footer">
              <table className="custom-table">
                <tbody>
                  <tr>
                    <td colSpan={3} className="py-2 pl-4 w-1/2">
                      <div className="flex flex-row justify-start items-start">
                        <span className="font-bold text-sm">
                          合計金額（税込）
                        </span>
                      </div>
                    </td>
                    <td colSpan={2} className="pr-4 w-1/2">
                      <div className="flex flex-row justify-end items-end">
                        <span className="font-bold text-sm">
                          {allTotalPrice}円
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomDetail;
