import { useDispatch } from "react-redux";

import { setAlert } from "reducers/actions";

import server from "helper/server";

const useChangePassword = () => {
  const dispatch = useDispatch();

  const changePassword = async (data, callback) => {
    let newAlertData = { show: true };
    // if (!data) {
    //   dispatch(
    //     setAlert({
    //       show: true,
    //       success: false,
    //       message: "添付データがありません",
    //     })
    //   );
    //   return;
    // }

    if (data) {
      const password = data.password ? data.password : "";
      const password_confirm = data.password_confirm
        ? data.password_confirm
        : "";
      if (
        password.replace(/\s+/g, "") !== password_confirm.replace(/\s+/g, "")
      ) {
        dispatch(
          setAlert({
            show: true,
            success: false,
            message: "パスワードの繰り返しが正しくありません",
          })
        );
        return;
      }

      const response = await server.call({
        url: "/users/change-password",
        data: {
          id: data.id,
          isAdmin: data.isAdmin,
          oldPassword: data.oldPassword,
          password,
          password_confirm,
        },
      });
      if (response.data) {
        let errMessage = "";
        newAlertData.success = response.data.success;
        if (response.data.type && response.data.type === "validation") {
          const errors = response.data.errors;
          if (errors && errors.length > 0) {
            errors.forEach((element) => {
              errMessage += element.message + `, <br />`;
            });
          }
          newAlertData.message = errMessage;
        } else {
          newAlertData.message = response.data.message;
        }
        response.data.success && callback && callback();
      } else {
        newAlertData.success = false;
        newAlertData.message = "エラー";
      }
      dispatch(setAlert(newAlertData));
      return;
    }
  };

  return [changePassword];
};

export default useChangePassword;
