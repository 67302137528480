// import TapWeekDays from "customComponents/Weather/TapWeekDays";
import CardWeekDays from "customComponents/Weather/CardWeekDays";
import CategoryGrid from "customComponents/Categories/CustomGrid";
import ConstructionsThumb from "customComponents/Constructions/CustomThumb";

import { useAuth } from "context/auth";

const HomePage = () => {
  const { userData } = useAuth();
  const role = userData ? userData.role : null;

  return (
    <>
      {/* category grid */}
      <CategoryGrid />

      {/* construction grid */}
      {role === "Customer" && <ConstructionsThumb role={role} />}

      <CardWeekDays />
    </>
  );
};

export default HomePage;
