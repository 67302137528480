import {
  SHOW_DOCUMENTS_DIALOG,
  DOCUMENTS_MODIFY_DATA,
  DOCUMENTS_EDIT_DATA,
  DOCUMENTS_LIST_DATA,
  CATEGORY_FILTER,
} from "reducers/actions/documentsTypes";

// Documents Dialogs
export const setDocumentsDialog = (isActive) => {
  return { type: SHOW_DOCUMENTS_DIALOG, payload: isActive };
};

// Documents crud
export const setDocumentsList = (data) => {
  return { type: DOCUMENTS_LIST_DATA, payload: data };
};

export const setDocumentsModifyData = (data) => {
  return { type: DOCUMENTS_MODIFY_DATA, payload: data };
};

export const setDocumentsEditData = (data) => {
  return { type: DOCUMENTS_EDIT_DATA, payload: data };
};

export const setCategoryFilter = (filter) => {
  return { type: CATEGORY_FILTER, payload: filter };
};
