import React from "react";
import { useSelector } from "react-redux";

import { BaseInput, BaseTextarea, BaseSearchSelect } from "baseComponents";

import { useAuth } from "context/auth";

const Form = (props) => {
  const { userData } = useAuth();
  const role = userData ? userData.role : null;

  const constructionsEditData = useSelector(
    (state) => state.constructions.constructionsEditData
  );
  const { changeModifyObject } = props;

  const changeValue = (name, value) => {
    changeModifyObject && changeModifyObject({ [name]: value });
  };

  return (
    <>
      <div className="grid grid-cols-1">
        <BaseSearchSelect
          label={"マンション"}
          name={"apartment_id"}
          value={role !== "Admin" ? userData.apartment_id || null : null}
          placeholder={"マンション"}
          disabled={true}
          objectName={"apartments"}
          textField={"apartment_name"}
          changeValue={changeValue}
        />
        <BaseInput
          label={"お部屋番号"}
          name={"room_number"}
          value={role === "Customer" ? userData.room_number || null : null}
          placeholder={"お部屋番号"}
          type="number"
          disabled={true}
          changeValue={changeValue}
        />
        <BaseInput
          label={"Construction name"}
          name={"construction_name"}
          value={constructionsEditData.construction_name || null}
          placeholder={"Construction name"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseTextarea
          label={"説明"}
          name={"description"}
          value={constructionsEditData.description || null}
          placeholder={"説明"}
          changeValue={changeValue}
          className="h-32"
        />
      </div>
    </>
  );
};

export default Form;
