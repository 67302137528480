import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";
import moment from "moment";

import { useAuth } from "context/auth";

import Tooltip from "components/Tooltip";
import Checkbox from "components/form/Checkbox";
import Button from "components/Button";

import {
  setAlert,
  setConfirmDialog,
  setChangePasswordDialog,
  setChangePasswordData,
} from "reducers/actions";

import { BasePagination, BaseGridLoading, BaseConfirm } from "baseComponents";

const Grid = (props) => {
  const dispatch = useDispatch();
  const { userData } = useAuth();
  const role = userData ? userData.role : null;
  // const userId = userData ? userData.id : null;

  const { edit, destroy, onPageChange, onPageSizeChange } = props;

  const [selectedItems, setSelectedItems] = useState([]);
  const [deleteId, setDeleteId] = useState(null);

  const usersListData = useSelector((state) => state.users.usersListData);
  const showGridLoading = useSelector((state) => state.custom.showGridLoading);

  const handleToggleSelection = (id) => {
    let newSelectedItems = selectedItems;
    if (newSelectedItems.includes(id)) {
      const index = newSelectedItems.indexOf(id);
      newSelectedItems.splice(index, 1);
      newSelectedItems = [...newSelectedItems];
    } else {
      newSelectedItems = [...newSelectedItems, id];
    }
    setSelectedItems(newSelectedItems);
  };

  const toggleSelectAll = () => {
    if (selectedItems.length) {
      setSelectedItems([]);
    } else {
      const newSelectedItems = usersListData.rows.map((item) => item.id);
      setSelectedItems(newSelectedItems);
    }
  };

  return (
    <>
      <BaseConfirm
        cancel={() => {
          dispatch(setConfirmDialog(false));
          dispatch(
            setAlert({
              show: true,
              success: false,
              message: "削除操作がキャンセルされました",
            })
          );
        }}
        accept={async () => {
          destroy && (await destroy(deleteId));
          dispatch(setConfirmDialog(false));
        }}
      >
        削除してもよろしいですか？
      </BaseConfirm>
      <div className="relative min-h-[130px]">
        {showGridLoading && <BaseGridLoading />}
        {/* List */}
        <div className="table-provider">
          <table className="table table-auto table_hoverable min-w-[800px]">
            <thead>
              <tr>
                <th className="w-px">
                  <Checkbox
                    checked={selectedItems.length}
                    partial={selectedItems.length < usersListData.rows.length}
                    onChange={toggleSelectAll}
                  />
                </th>
                <th className="text-left uppercase w-1/6">マンション</th>
                <th className="text-left uppercase w-1/6">ユーザー名</th>
                <th className="text-left uppercase w-1/6">氏名(姓)</th>
                <th className="text-left uppercase w-1/6">氏名(名)</th>
                {/* <th className="text-left uppercase">Email</th> */}
                <th className="text-left uppercase w-1/6">役割</th>
                <th className="text-left uppercase w-1/8">作成日</th>
                <th className="text-left uppercase w-1/8">更新日</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {usersListData.rows.map((item, key) => {
                return (
                  <tr
                    key={key}
                    className={classNames({
                      row_selected: selectedItems.includes(item.id),
                    })}
                  >
                    <td>
                      <Checkbox
                        checked={selectedItems.includes(item.id)}
                        onChange={() => handleToggleSelection(item.id)}
                      />
                    </td>
                    <td>
                      {item.Apartment ? item.Apartment.apartment_name : ""}
                    </td>
                    <td>{item.username}</td>
                    <td>{item.firstname}</td>
                    <td>{item.lastname}</td>
                    {/* <td>{item.email}</td> */}
                    <td>{item.role}</td>
                    <td className="text-left">
                      {item.created_at
                        ? moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")
                        : null}
                    </td>
                    <td className="text-left">
                      {item.updated_at
                        ? moment(item.updated_at).format("YYYY-MM-DD HH:mm:ss")
                        : null}
                    </td>
                    <td className="text-right whitespace-nowrap">
                      <div className="inline-flex ml-auto">
                        {/* Change Password */}
                        {role === "Admin" && (
                          <Tooltip
                            content={"パスワード変更"}
                            placement="bottom"
                          >
                            <Button
                              outlined
                              className="btn btn-icon btn_outlined btn_secondary"
                              onClick={() => {
                                dispatch(setChangePasswordDialog(true));
                                dispatch(
                                  setChangePasswordData({
                                    id: item.id,
                                    isAdmin: true,
                                    username: item.username,
                                    firstname: item.firstname,
                                    lastname: item.lastname,
                                  })
                                );
                              }}
                            >
                              <span className="la la-key" />
                            </Button>
                          </Tooltip>
                        )}
                        {/* Edit */}
                        {role === "Admin" && (
                          <Tooltip content={"編集"} placement="bottom">
                            <Button
                              className="btn btn-icon btn_outlined btn_primary ml-2"
                              onClick={() => edit && edit(item)}
                            >
                              <span className="la la-edit" />
                            </Button>
                          </Tooltip>
                        )}
                        {/* Delete */}
                        {role === "Admin" && item.id !== 1 && (
                          <Tooltip content={"削除"} placement="bottom">
                            <Button
                              className="btn btn-icon btn_outlined btn_danger ml-2"
                              onClick={() => {
                                setDeleteId(item.id);
                                dispatch(setConfirmDialog(true));
                              }}
                            >
                              <span className="la la-trash-alt" />
                            </Button>
                          </Tooltip>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <BasePagination
          totalCount={usersListData ? usersListData.count : 0}
          onPageChange={(page) => onPageChange && onPageChange(page)}
          onPageSizeChange={(size) =>
            onPageSizeChange && onPageSizeChange(size)
          }
        />
      </div>
    </>
  );
};

export default Grid;
