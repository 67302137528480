import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setConfirmData } from "reducers/actions";

import Button from "components/Button";
import Modal, { ModalBody } from "components/Modal";

const BaseConfirmData = (props) => {
  const dispatch = useDispatch();

  const { yesLabel, noLabel } = props;

  const showConfirmData = useSelector((state) => state.custom.showConfirmData);

  if (showConfirmData) {
    return (
      <Modal
        active={showConfirmData.show}
        onClose={() => dispatch(setConfirmData({ show: false }))}
      >
        <ModalBody className="mx-3 py-3">
          <div className="w-64 max-sm:w-full">
            <div className="w-full block text-xl text-center mb-5">
              {showConfirmData.message}
            </div>
            <div className="w-full flex justify-center gap-2">
              <Button
                color="success"
                className="uppercase"
                onClick={() =>
                  showConfirmData.accept && showConfirmData.accept()
                }
              >
                {yesLabel || "はい"}
              </Button>
              <Button
                color="danger"
                className="uppercase"
                outlined
                onClick={() =>
                  showConfirmData.cancel && showConfirmData.cancel()
                }
              >
                {noLabel || "いいえ"}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  } else {
    return <div></div>;
  }
};

export default BaseConfirmData;
